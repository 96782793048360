import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Orders from "./pages/Orders";
import NewOrder2 from "./pages/NewOrder2";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Results from "./pages/Results";
import Shipments from "./pages/Shipments";
import NewShipment from "./pages/NewShipment";
import NewSample from "./pages/NewSample";
import Samples from "./pages/Samples";
import Setup from "./pages/Setup";
import Facilities from "./pages/Facilities";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Patients from "./pages/Patients";
import Activate from "./pages/Register";
import ViewPDF from "./pages/ViewPDF";
import Criticals from "./pages/Criticals";
import { ProtectedRoute } from "./components/PrivateRoute";
import { decodeCookie, SessionContext } from "./hooks/getContext";
import { checkToken } from "./services/auth";
import { useLocation } from "react-router-dom";
import OrderDisplay from "./components/OrderDisplay";
import PatientPage from "./pages/PatientPage";
import PatientEdit from "./pages/PatientEdit";
import ShipmentView from "./components/ShipmentView";
import NewPatient from "./pages/NewPatient";
import Billing from "./pages/Billing";
import Interfaces from "./pages/Interfaces";
import PatientResult from "./pages/PatientResult";
import PatientOrder from "./pages/PatientOrder";
import OrderReview from "./components/OrderReview";
import ChartNotesPDF from "./pages/ChartNotesPDF";

LicenseInfo.setLicenseKey(
    "4f1c625e62d46a23c8f2580d053085d4Tz01OTE5NCxFPTE3MDcxNjE5MDc4NjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = decodeCookie();
        if (token !== null) {
            checkToken().then((r) => {
                if (r.ok) {
                    setUser(token);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setUser(null);
                }
            });
        } else {
            setLoading(false);
            setUser(null);
        }
    }, [location]);

    return (
        <SessionContext.Provider value={{ user, setUser, loading, setLoading }}>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/orders"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <Orders />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/orders/:orderID"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <OrderDisplay withNav={true} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/orders/:orderID/chartNotes"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <ChartNotesPDF />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/orders/:orderID/resultReport"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <ViewPDF />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/orders/:orderID/printOrder"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <ViewPDF pdfType={"order"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/orders/:orderID/attachment/:attachmentUUID"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <ViewPDF />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/patientOrders/:orderID"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <OrderReview />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/criticals"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <Criticals />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/samples"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <Samples />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/neworder"
                    element={
                        <ProtectedRoute requiredRoles={[]} excludedRoles={["Sample Handler"]}>
                            <NewOrder2 />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/newsample"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <NewSample />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/shipments"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <Shipments />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/shipments/:shipmentID"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <ShipmentView />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/newshipment"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <NewShipment />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/results"
                    element={
                        <ProtectedRoute requiredRoles={["Admin", "Technician"]}>
                            <Results />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/patients/:patientID"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <PatientPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/newPatient"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <NewPatient />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/patients/:patientID/attachment/:attachmentUUID"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <ViewPDF />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/patients/:patientID/edit"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <PatientEdit />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/patients"
                    element={
                        <ProtectedRoute requiredRoles={[]}>
                            <Patients />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/billing"
                    element={
                        <ProtectedRoute
                            requiredRoles={["Admin", "Finance", "Billing"]}
                        >
                            <Billing />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/facilities"
                    element={
                        <ProtectedRoute
                            requiredRoles={[
                                "Admin",
                                "Technician",
                                "Customer Service",
                            ]}
                        >
                            <Facilities />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup"
                    element={
                        <ProtectedRoute requiredRoles={["Admin", "Technician"]}>
                            <Setup />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup/tests"
                    element={
                        <ProtectedRoute>
                            <Setup tab={"tests"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup/profiles"
                    element={
                        <ProtectedRoute>
                            <Setup tab={"profiles"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup/methods"
                    element={
                        <ProtectedRoute>
                            <Setup tab={"methods"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup/reflexes"
                    element={
                        <ProtectedRoute>
                            <Setup tab={"reflexes"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup/departments"
                    element={
                        <ProtectedRoute>
                            <Setup tab={"departments"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup/laboratories"
                    element={
                        <ProtectedRoute>
                            <Setup tab={"laboratories"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/setup/feeschedules"
                    element={
                        <ProtectedRoute>
                            <Setup tab={"feeschedules"} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/interfaces"
                    element={
                        <ProtectedRoute requiredRoles={["Admin", "Technician"]}>
                            <Interfaces />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/viewPDF"
                    element={
                        <ProtectedRoute>
                            <ViewPDF />
                        </ProtectedRoute>
                    }
                />

                <Route path="/result" element={<PatientResult />} />
                <Route path="/patientOrder" element={<PatientOrder />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/activate" element={<Activate />} />
            </Routes>
        </SessionContext.Provider>
    );
}

export default App;
