import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import StepContent from '@mui/material/StepContent';
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import mdTheme from "../../components/Theme";
import { useMediaQuery } from "@mui/material";
import { FormikProps, useFormikContext } from "formik";
import { RefObject } from "react";

// const steps = [
// "Select patient",
// "Select tests",
// "Select payment method",
// "Schedule Collection",
// ];

type OrderStepperProps = {
    activeStep: number,
    handleNext: () => void,
    handleBack: () => void,
    handleReset: () => void,
    steps: string[],
    formikRef: RefObject<FormikProps<any>>,
}

export default function OrderStepper(props: OrderStepperProps) {
    const [skipped, setSkipped] = React.useState(new Set());
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));


    const isStepOptional = (step: number) => {
        let optionalSteps: number[] = [];
        return optionalSteps.includes(step);
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleSkip = () => {
        if (!isStepOptional(props.activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(props.activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        props.handleReset();
    };

    if (!isMobile) {
        return (
            <Box >
                <Stepper activeStep={props.activeStep}>
                    {props.steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                            // @ts-ignore
                            labelProps.optional = (
                                <Typography variant="caption">Optional</Typography>
                            );
                        }
                        if (isStepSkipped(index)) {
                            // @ts-ignore
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {
                    props.activeStep === props.steps.length ? (
                        <React.Fragment>
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <Button
                                    onClick={handleReset}
                                    color="secondary"
                                    sx={{ color: "white" }}
                                    variant="contained"
                                >
                                    Enter New Order
                                </Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                Step {props.activeStep + 1}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={props.activeStep === 0}
                                    onClick={props.handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                {isStepOptional(props.activeStep) && (
                                    <Button
                                        color="inherit"
                                        onClick={handleSkip}
                                        sx={{ mr: 1 }}
                                    >
                                        Skip
                                    </Button>
                                )}
                                <Box sx={{ flex: "1 1 auto" }} />

                                <Button onClick={props.handleNext}>
                                    {props.activeStep === props.steps.length - 1
                                        ? "Finish"
                                        : "Next"}
                                </Button>
                            </Box>
                        </React.Fragment>
                    )
                }
            </Box >
        );
    } else {
        return (
            <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={props.activeStep} orientation="vertical">
                    {props.steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel
                                optional={
                                    index === 3 ? (
                                        <Typography variant="caption">
                                            Last step
                                        </Typography>
                                    ) : null
                                }
                            >
                                {step}
                            </StepLabel>
                            <StepContent>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={props.handleNext}
                                            disabled={props.formikRef.current?.isSubmitting}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === props.steps.length - 1
                                                ? "Finish"
                                                : "Continue"}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={props.handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {props.activeStep === props.steps.length && (
                    <>
                        <Button
                            onClick={handleReset}
                            color="secondary"
                            sx={{ color: "white" }}
                            variant="contained"
                        >
                            Enter New Order
                        </Button>
                    </>
                )}
            </Box>
        );
    }
}
