import React, { useCallback, useEffect, useState } from "react";
import { DataGridPro, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import {
    GridToolbar,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Box } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/system";
import { searchShipmentBySampleID } from "../services/shipments";
import { useNavigate } from "react-router";
import SaveIcon from "@mui/icons-material/Save";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonOutline from "@mui/icons-material/PersonOutline";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import Download from "@mui/icons-material/Download";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import {
    paymentMethodIndex,
    financialClassIndex,
    formatFee,
} from "../services/util";
import PatientInsurancesTable from "../components/InsurancesTable";
import { createBillingExport } from "../services/orders";
import {
    GetBillingStatusChip,
    GetOrderStatusChip,
} from "../components/StatusChips";
import TableFrame from "../components/TableFrame";
import { DataTableContainerNoButton } from "../components/TableContainer";

export default function BillingTable({
    billings,
    setBillings,
    refresh,
    setRefresh,
    disableExport,
    getBillings,
    viewNote,
    addNote,
    update,
    showActions,
}) {
    const [allBillings, setAllBillings] = useState([]);
    const [actions, setActions] = useState(true);
    const navigate = useNavigate();
    const [total, setTotal] = useState("$0.00");
    const [markAsCompleteDialog, setMarkAsCompleteDialog] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (showActions === false) {
            setActions(false);
        }
    }, []);

    useEffect(() => {
        getBillings()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load billings");
                }
                return p.json();
            })
            .then((p) => {
                let t = 0;
                for (let b of p) {
                    t += b.Price / 100;
                }
                setTotal(`$${t.toFixed(2)}`);
                setAllBillings(p);
            });
    }, [refresh]);

    if (allBillings.length > 0) {
        console.log(allBillings[0])
    }

    const renderPatientButton = (params) => {
        return (
            <PersonOutline
                sx={{ "&:hover": { color: "black" } }}
                color="primary"
                onClick={() => navigate("/patients/" + params.row.PatientID)}
            />
        );
    };

    const renderOrderButton = (params) => {
        return (
            <ReceiptIcon
                sx={{ "&:hover": { color: "black" } }}
                color="primary"
                onClick={() => navigate("/orders/" + params.id)}
            />
        );
    };
    const renderNotesButton = (params) => {
        let notesIcon;
        let rejectionIcon;
        if (params.row.Notes.length > 0) {
            notesIcon = (
                <SpeakerNotesIcon
                    onClick={() => viewNote(params.row.Notes)}
                    sx={{ "&:hover": { color: "black" } }}
                    color="primary"
                />
            );
        }
        if (params.row.RejectionID != null) {
            rejectionIcon = (
                <ThumbDownIcon
                    sx={{ "&:hover": { color: "black" } }}
                    color="error"
                />
            );
        }

        return (
            <>
                {notesIcon}
                {rejectionIcon}
            </>
        );
    };

    const columns = [
        { field: "ID", headerName: "Order ID", width: 95 },
        {
            field: "Order",
            headerName: "View Order",
            width: 105,
            renderCell: renderOrderButton,
        },
        {
            field: "Patient",
            headerName: "View Patient",
            width: 105,
            renderCell: renderPatientButton,
        },
        {
            field: "Results",
            headerName: "Billable Tests",
            width: 105,
            valueGetter: (params) => params.row?.Results.map((r) => r.Test?.Name).join(", ")
        },
        {
            field: "Notes",
            headerName: "Notes",
            width: 75,
            renderCell: renderNotesButton,
        },
        {
            field: "Facility",
            headerName: "Facility",
            width: 150,
            valueGetter: (row) => row.row.Facility.Name,
        },
        {
            field: "Status",
            headerName: "Order Status",
            width: 170,
            renderCell: (params) => <GetOrderStatusChip status={params.row?.Status} />,
        },
        {
            field: "BillingStatus",
            headerName: "Billing Status",
            width: 150,
            renderCell: (params) =>
                GetBillingStatusChip(params.row?.BillingStatus),
        },
        {
            field: "PaymentMethod",
            headerName: "Payment Method",
            width: 150,
            valueGetter: (row) => paymentMethodIndex[row.row.PaymentMethod],
        },
        {
            field: "Financial Class",
            headerName: "Financial Class",
            width: 150,
            valueGetter: (row) =>
                financialClassIndex[row.row.Patient.FinancialClass],
        },
        {
            field: "Elibility Status",
            headerName: "Eligiblity Status",
            width: 120,
        },
        {
            field: "Value",
            headerName: "Value",
            width: 150,
            valueGetter: (row) => formatFee.format(row.row.Price / 100),
        },
    ];

    const PatientInsurancesDetail = ({ row }) => {
        const [insRow, setInsRow] = useState(row.row.Patient.PatientInsurances);

        if (row.row.Patient.PatientInsurances.length === 0) {
            return undefined;
        }

        return (
            <Box>
                <PatientInsurancesTable
                    insurances={insRow}
                    setInsurances={setInsRow}
                />
            </Box>
        );
    }

    const renderDetail = useCallback(row => {
        if (row.row.Patient.PatientInsurances.length === 0) {
            return undefined;
        }
        return <PatientInsurancesDetail row={row} />
    }, []);

    function CustomFooterTotalComponent(props) {
        return (
            <Box
                sx={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <strong>{billings.length} rows selected</strong>
                <strong>Total : {props.total}</strong>
            </Box>
        );
    }

    const handleCreateExport = async (markComplete) => {
        try {
            const response = await createBillingExport(billings, markComplete);
            if (!response.ok) {
                throw new Error("Failed to create billing export");
            }
            const data = await response.blob();
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = "billing_export.csv";
            link.click();
            // It's necessary to revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(url);
            setRefresh(!refresh);
        } catch (e) {
            setError(e.message);
        }
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ width: "100%", display: "block" }}>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                >
                    <GridToolbarQuickFilter />
                    <Button
                        variant="contained"
                        disabled={billings.length === 0}
                        color="primary"
                        startIcon={<Download />}
                        onClick={() => {
                            if (disableExport) {
                                handleCreateExport(false)
                            } else {
                                setMarkAsCompleteDialog(true)
                            }
                        }}
                    >
                        Export
                    </Button>
                </Stack>
            </GridToolbarContainer>
        );
    }

    return (
        <React.Fragment>
            <Dialog open={markAsCompleteDialog}>
                <DialogContent>
                    <Typography>
                        Do you want to mark these orders as billed?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setMarkAsCompleteDialog(false);
                            handleCreateExport(false);
                        }}
                    >
                        No
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        loadingPosition="start"
                        color="success"
                        variant="contained"
                        onClick={() => {
                            setMarkAsCompleteDialog(false);
                            handleCreateExport(true);
                        }}
                    >
                        <span>Yes</span>
                    </Button>
                </DialogActions>
            </Dialog>
            <TableFrame>
                <DataTableContainerNoButton>
                    <DataGridPro
                        getRowId={(row) => row.ID}
                        getDetailPanelContent={renderDetail}
                        getDetailPanelHeight={() => "auto"} // Height based on the content.
                        columnVisibilityModel={{
                            Action: actions,
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "ID", sort: "desc" }],
                            },
                        }}
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterTotalComponent,
                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                            footer: { total },
                        }}
                        onRowSelectionModelChange={setBillings}
                        rowSelectionModel={billings}
                        pageSize={100}
                        checkboxSelection={true}
                        rows={allBillings}
                        columns={columns}
                    />
                </DataTableContainerNoButton>
            </TableFrame>
        </React.Fragment>
    );
}
