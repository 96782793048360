import React, { useContext } from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ScienceIcon from "@mui/icons-material/Science";
import { SessionContext } from "../hooks/getContext";
import { Link } from "react-router-dom";

const withLink = (to, children) => (
    <Link style={{ color: "rgba(0, 0, 0, 0.54)" }} to={to}>
        {children}
    </Link>
);

const actions = [
    {
        icon: withLink(
            "/newshipment",
            <LocalShippingIcon sx={{ display: "block" }} />
        ),
        name: "Shipment",
    },
    {
        icon: withLink(
            "/newpatient",
            <PersonAddIcon sx={{ display: "block" }} />
        ),
        name: "Patient",
    },
    {
        icon: withLink("/newsample", <ScienceIcon sx={{ display: "block" }} />),
        name: "Sample",
    },
    {
        icon: withLink("/neworder", <ReceiptIcon sx={{ display: "block" }} />),
        name: "Order",
    },
];

export default function AddSpeedDial() {
    const session = useContext(SessionContext);
    const canOrder = !session?.user?.Roles.includes("Sample Handler")

    const blockIfCantOrder = ["Order", "Patient"]

    return (
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 32, right: 32 }}
            icon={<SpeedDialIcon />}
        >
            {actions.filter((action) => canOrder || !blockIfCantOrder.includes(action.name)).map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                />
            ))}
        </SpeedDial>
    );
}
