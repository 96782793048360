import React, { useEffect, useState } from "react";
import { validateDiagnosis } from "../services/orders";
import {
    Chip,
    Divider,
    ListItemText,
    Table,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    Typography,
    TableBody,
    Paper,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { apiFetch } from "../services/fetch";
import DiagnosisCodeTable from "./DiagnosisCodesTable";
import ErrorAlert from "./ErrorAlert";
import DiagnosisDataGrid from "./DiagnosisDataGrid";

function SupportedDialog({ supportedResponse, setSupportedResponse, setDiagnosis }) {

    const handleSelection = (selectedCodes) => {
        if (!supportedResponse) {
            return
        }

        setDiagnosis(supportedResponse.filter((d) => selectedCodes.includes(d?.Code)))
    }

    return (
        <Dialog open={supportedResponse !== null}>
            <DialogTitle>View Supported Diagnosis Codes</DialogTitle>
            <DialogContent>
                {supportedResponse?.length === 0 || !supportedResponse ? "There are no supported diagnosis codes defined for this cpt code." :
                    <Box height={"70vh"}>
                        <DiagnosisDataGrid
                            diagnosisCodes={supportedResponse}
                            setSelected={handleSelection}
                        />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setSupportedResponse(null)}>Close</Button>
            </DialogActions>

        </Dialog>
    )
}


function DiagnosisValidityTable({ diagnosisValidity, testDetails, setDiagnosis }) {
    const [loadingSupported, setLoadingSupported] = useState(false);
    const [supportedResponse, setSupportedResponse] = useState(null);
    const [error, setError] = useState(null);


    const viewSupported = async (cpt_code) => {
        setLoadingSupported(true)
        try {
            const response = await apiFetch(`/orders/diagnosis/${cpt_code}`)
            setSupportedResponse(response)
            setError(null)
            setLoadingSupported(false)
        } catch (e) {
            console.log(e)
            setError("Failed to load supported codes.")
        } finally {
            setLoadingSupported(false)
        }
    }

    const getLCDNCD = (cpt_code) => {
        if (
            diagnosisValidity?.CPTCodes === null ||
            diagnosisValidity.length === 0
        ) {
            return;
        }
        const ncds = [];
        for (let r of diagnosisValidity?.CPTCodes) {
            if (r.CPTCode === cpt_code) {
                ncds.push(r.ConvertedNCDRule);
            }
        }

        return (
            <>
                {[...new Set(ncds)].map((s) => (
                    <Typography>{s}</Typography>
                ))}
            </>
        );
    };

    const getSupportedByString = (cpt_code) => {
        if (
            diagnosisValidity?.CPTCodes === null ||
            diagnosisValidity.length === 0
        ) {
            return;
        }
        const supportedBy = [];
        for (let r of diagnosisValidity?.CPTCodes) {
            if (r.CPTCode === cpt_code) {
                if (r.Supported) {
                    supportedBy.push(r.SupportedBy);
                }
            }
        }

        return (
            <>
                {supportedBy.map((s) => (
                    <Chip label={s} color="primary" />
                ))}
            </>
        );
    };

    const getValidStatus = (cpt_code) => {
        console.log(cpt_code);
        if (diagnosisValidity === undefined || diagnosisValidity.length === 0) {
            return <></>;
        }
        if (diagnosisValidity?.CPTCodes === null) {
            return (
                <Tooltip title="Unabled to check if this test is supported.">
                    <CheckCircleIcon color="disabled" />
                </Tooltip>
            );
        }

        console.log(diagnosisValidity);
        for (let r of diagnosisValidity?.CPTCodes) {
            if (r.CPTCode === cpt_code) {
                if (r.Supported) {
                    return (
                        <Tooltip title="This test is supported.">
                            <CheckCircleIcon color="success" />
                        </Tooltip>
                    );
                } else {
                    return (
                        <Tooltip title="This test is not supported.">
                            <NotInterestedIcon color="error" />
                        </Tooltip>
                    );
                }
            }
        }
        return (
            <Tooltip title="Unable to check if this test is supported.">
                <CheckCircleIcon color="disabled" />
            </Tooltip>
        );
    };

    if (!testDetails || testDetails.length === 0) {
        return (
            <></>
        )
    }

    return (
        <Paper
            sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                m: 2,
            }}
        >
            <SupportedDialog supportedResponse={supportedResponse} setSupportedResponse={setSupportedResponse} setDiagnosis={setDiagnosis} />
            <Typography variant="h5" gutterBottom>
                Diagnosis Validity
            </Typography>
            <ListItemText>
                The following table displays the ordered tests and whether the
                provided diagnosis codes support the ordered tests.
            </ListItemText>
            <TableContainer style={{ maxHeight: 400 }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow key={-1}>
                            <TableCell>Test</TableCell>
                            <TableCell>CPT Code</TableCell>
                            <TableCell>Supported</TableCell>
                            <TableCell>Supported By</TableCell>
                            <TableCell>View Supporting Codes</TableCell>
                            <TableCell>LCD/NCD</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {testDetails.map((t, index) => (
                            <>
                                <TableRow key={index}>
                                    <TableCell>{t.Name}</TableCell>
                                    <TableCell>{t.CPTCode}</TableCell>
                                    <TableCell>
                                        {getValidStatus(t.CPTCode)}
                                    </TableCell>
                                    <TableCell>
                                        {getSupportedByString(t.CPTCode)}
                                    </TableCell>
                                    <TableCell>
                                        <Button disabled={!t.CPTCode || loadingSupported} onClick={() => viewSupported(t.CPTCode)}>View</Button>
                                    </TableCell>
                                    <TableCell>
                                        {getLCDNCD(t.CPTCode)}
                                    </TableCell>
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ErrorAlert error={error} />
        </Paper>
    );
}

export { DiagnosisValidityTable };
