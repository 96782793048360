import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button,
    Dialog,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { FormatDateTime } from "../services/util";

export default function ViewNotesDialog({ notes, handleClose, open }) {
    return (
        <Dialog open={open}>
            <DialogTitle>Note</DialogTitle>
            <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Note</TableCell>
                                <TableCell>Date Added</TableCell>
                                <TableCell>Added By</TableCell>
                            </TableRow>
                            {notes.map((n) => {
                                return (
                                    <TableRow key={n.ID}>
                                        <TableCell>{n.Note}</TableCell>
                                        <TableCell>
                                            {FormatDateTime(n.CreatedAt)}
                                        </TableCell>
                                        <TableCell>
                                            {n.AddedBy.Email}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableHead>
                    </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
