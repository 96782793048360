import { Typography, Stack, Button, Divider, InputLabel } from "@mui/material";
import { apiFetch } from "../../services/fetch";
import React, { useEffect, useState, useContext } from "react";
import { paymentMethodIndex, formatFee, FormatDateTime } from "../../services/util";
import ConfirmDialog from "../ConfirmDialog";
import { GetOrderStatusChip, GetOrderApprovalChip } from "../StatusChips";
import ErrorAlert from "../ErrorAlert";
import { SessionContext } from "../../hooks/getContext";


function OrderMetadata({ orderDetails, setPaymentMethod, refresh = () => { } }) {
    const [confirm, setConfirm] = useState(false)
    const [approve, setApprove] = useState(false)
    const [reject, setReject] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const session = useContext(SessionContext);
    const userRoles = session?.user?.Roles;
    const [confirmMsg, setConfirmMsg] = useState({
        title: "", message: ""
    })

    useEffect(() => {
        if (approve) {
            setConfirmMsg({ title: "Confirm approval", message: "Are you sure you want to approve this order?" })
            return
        }
        if (reject) {
            setConfirmMsg({ title: "Confirm rejection", message: "Are you sure you want to reject this order?" })
            return
        }
    }, [approve, reject])


    const approveOrder = (approved) => {
        const saveOrder = async () => {
            try {
                setLoading(true)
                let data = {
                    Approved: approved
                }
                await apiFetch(`/orders/${orderDetails?.ID}/approve`, "POST", data)
                refresh()

            } catch (e) {
                setError("Failed to update order.")
            } finally {
                setLoading(false)
                setConfirm(false)
            }

        }
        saveOrder()

    }

    return (
        <>
            <ConfirmDialog
                open={confirm}
                cancel={() => { setConfirm(false); setApprove(false); setReject(false) }}
                loading={loading}
                confirm={approve ? () => approveOrder(true) : () => approveOrder(false)}
                {...confirmMsg}
            />
            <Typography variant="p" align="left" display="block">
                ID: {orderDetails.ID}
            </Typography>
            <Stack direction="row" spacing={2}>
                Status:  <GetOrderStatusChip status={orderDetails?.Status} />
            </Stack>
            {
                orderDetails?.RequiresApproval &&
                <Stack direction="row" spacing={2} >
                    Review Status:  <GetOrderApprovalChip approved={orderDetails?.Approved} />
                </Stack>
            }
            <Typography variant="p" align="left" display="block">
                Submitted: {FormatDateTime(orderDetails.CreatedAt)}
                <br />
                Submitted By: {orderDetails.AddedBy.Name} -{" "}
                {orderDetails.AddedBy.Email}
                <br />
                Updated: {FormatDateTime(orderDetails.UpdatedAt)}
                <br />
                <b>
                    Payment Method:{" "}
                    <Button
                        sx={{ padding: "2px 8px" }}
                        onClick={() => setPaymentMethod(true)}
                        disabled={orderDetails?.BillingStatus === "Billed"}
                    >
                        {paymentMethodIndex[orderDetails.PaymentMethod]}
                    </Button>
                </b>
                <br />
                {orderDetails.PaymentMethod in [0, 1] && (
                    `Price: ${formatFee.format(orderDetails?.Price / 100)}`
                )}
            </Typography>
            {orderDetails?.Approved !== null &&
                <Stack direction="column">
                    <Stack direction="column" spacing={1} alignItems="center" mt="5px" mb="5px">
                        <Divider orientation="horizontal" />
                        <InputLabel>Review Details</InputLabel>
                    </Stack>
                    {orderDetails.Approved !== null && <><br />Reviewed By: {orderDetails.ReviewedBy?.FirstName} {orderDetails.ReviewedBy?.LastName} -{" "}
                        {orderDetails.ReviewedBy?.Email}</>}
                    {orderDetails.ReviewedAt && <><br />Reviewed At: {FormatDateTime(orderDetails?.ReviewedAt)}</>}
                </Stack>
            }

            {
                orderDetails?.RequiresApproval &&
                orderDetails?.Approved === null &&
                !userRoles.includes("Sample Handler") &&
                <>
                    <Stack direction="column" spacing={1} alignItems="center">
                        <Divider orientation="horizontal" />
                        <InputLabel>Review Actions</InputLabel>
                        <Stack direction="row" spacing={2} justifyContent="space-between" width={"100%"}>
                            <Button variant="contained" color="success" onClick={() => {
                                setConfirm(true); setApprove(true)
                            }}>Approve</Button>
                            <Button variant="contained" color="error" onClick={() => {
                                setConfirm(true); setReject(true)
                            }}
                            >Reject</Button>
                        </Stack >
                    </Stack >
                </>
            }
            <ErrorAlert error={error} />
        </>
    );
};


export default OrderMetadata;
