import { useState, useEffect } from "react";
import { GridToolbar, DataGridPro } from "@mui/x-data-grid-pro";
import { formatFee, HoursToHumanReadable } from "../services/util";

export default function TestsTable({
    facility,
    tests,
    setTests,
    getTests,
    specimenTypeFilter,
    stabilityFilter,
    categoryFilter,
    checkboxes,
}) {
    const [allTests, setAllTests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Reorders allTests to have checked at the top
        const newRows = [...allTests].sort((a, b) => {
            const aIsChecked = tests.includes(a.ID);
            const bIsChecked = tests.includes(b.ID);

            if (aIsChecked && !bIsChecked) {
                return -1;
            } else if (!aIsChecked && bIsChecked) {
                return 1;
            } else {
                return 0;
            }
        });

        setAllTests(newRows);
    }, [tests]);

    const getSelfPayFee = (row) => {
        for (let i of row.row.Fees) {
            if (i.FeeSchedule.FinancialClass === 1) {
                return formatFee.format(i.Fee / 100);
            }
        }
        return "N/A";
    };
    const getClientBillFee = (row) => {
        if (facility === undefined) {
            return "N/A";
        }

        for (let i of row.row.Fees) {
            if (
                i.FeeSchedule.FinancialClass === 0 &&
                i.FeeSchedule.ID === facility.FeeScheduleID
            ) {
                return formatFee.format(i.Fee / 100);
            }
        }
        return "N/A";
    };

    const columns = [
        { field: "Code", headerName: "Code", width: 70 },
        { field: "Name", headerName: "Name", width: 220 },
        { field: "Description", headerName: "Description", width: 220 },
        { field: "CPTCode", headerName: "CPT Code", width: 100 },
        {
            field: "Department",
            headerName: "Department",
            width: 125,
            valueGetter: (row) => row.row.Department.Name,
        },
        {
            field: "Stability",
            headerName: "Stability",
            width: 100,
            valueGetter: (row) => HoursToHumanReadable(row.row.Stability),
        },
        {
            field: "MaxTAT",
            headerName: "Max TAT",
            width: 100,
            valueGetter: (row) => HoursToHumanReadable(row.row.MaxTAT),
        },
        {
            field: "SelfPay",
            headerName: "Self Pay Price",
            width: 175,
            valueGetter: getSelfPayFee,
        },
        {
            field: "ClientBill",
            headerName: "Client Bill Price",
            width: 175,
            valueGetter: getClientBillFee,
        },
    ];

    useEffect(() => {
        getTests()
            .then((t) => {
                let tests = t;

                console.log("tests checking category filter", categoryFilter)
                if (categoryFilter) {
                    tests = t.filter((test) =>
                        test.CategoryID === categoryFilter
                    );
                }

                if (specimenTypeFilter) {
                    console.log("filtering for stability", specimenTypeFilter)
                    // filter tests that have this specimenType
                    tests = t.filter((test) =>
                        test.SpecimenTypes.some((specType) =>
                            specimenTypeFilter.includes(specType.ID)
                        )
                    );
                }

                if (stabilityFilter) {
                    console.log("filtering for stability", stabilityFilter)
                    tests = tests.filter((test) => {
                        let isStable = true;
                        for (let sampleID in stabilityFilter) {
                            let specimenTypeStabilities =
                                stabilityFilter[sampleID];
                            for (let specimenTypeId in specimenTypeStabilities) {
                                let stability =
                                    specimenTypeStabilities[specimenTypeId];

                                // stability contains how old the sample is in hours
                                // thus the test.Stability value must be greater thumbnail
                                // the sample is old to be stable
                                if (test.Stability < stability) {
                                    isStable = false;
                                    break;
                                }
                            }
                            if (!isStable) break;
                        }
                        return isStable;
                    });
                }

                setAllTests(tests);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [categoryFilter, stabilityFilter, specimenTypeFilter])

    const handleSelect = (v, e) => {
        if (v.length === 0) {
            return;
        }
        let td = [];
        const tlookup = e.api.state.rows.idRowsLookup;
        for (let t of v) {
            td.push(tlookup[t]);
        }
        setTests(v);
    };

    return (
        <DataGridPro
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            getRowId={(row) => row.ID}
            loading={loading}
            pagination
            pageSize={50}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: "__check__", sort: "desc" }],
                },
            }}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={setTests}
            rowSelectionModel={tests}
            checkboxSelection={checkboxes}
            rows={allTests}
            columns={columns}
        />
    );
}
