import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { FormatDate } from "../services/util";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Link } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Stack, width } from "@mui/system";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { searchShipmentBySampleID } from "../services/shipments";
import { useNavigate } from "react-router";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ViewRejectionDialog from "./ViewRejectionDialog";
import { GetSampleStatusChip } from "./StatusChips";
import { FormatDateUTC } from "../services/util";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default function SamplesTable({
    samples,
    setSamples,
    refresh,
    getSamples,
    viewNote,
    reject,
    restore,
    addNote,
    update,
    showActions,
}) {
    const [allSamples, setAllSamples] = useState([]);
    const [actions, setActions] = useState(true);
    const [loading, setLoading] = useState(true);
    const [viewRejection, setViewRejection] = useState(false);
    const [rejection, setRejection] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (showActions === false) {
            setActions(false);
        }
    }, []);

    useEffect(() => {
        setLoading(true);
        getSamples().then((p) => {
            setAllSamples(p);
            setLoading(false);
        });
    }, [refresh]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const getShipment = (params) => {
        return params.row?.Shipments?.map((shipment) => {
            return (
                <LocalShippingIcon
                    sx={{ "&:hover": { color: "#1976d2" } }}
                    onClick={() => navigate("/shipments/" + shipment.ID)}
                />
            );
        });
    };

    const renderButtons = (params) => {
        return (
            <Stack
                divider={<Divider flexItem orientation="vertical" />}
                direction="row"
                spacing={1}
            >
                <strong>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        // style={{ marginLeft: 16 }}
                        onClick={() => addNote(params.row.ID)}
                    >
                        Add Note
                    </Button>
                </strong>
                {params.row.Status === "Rejected" ? (
                    <strong>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            // style={{ marginLeft: 16 }}
                            onClick={() => restore(params.row.ID)}
                        >
                            Restore
                        </Button>
                    </strong>
                ) : (
                    <>
                        <strong>
                            <Button
                                variant="contained"
                                disabled={
                                    params.row.Status === "Transferred" ||
                                    params.row.Status === "Rejected"
                                }
                                color="error"
                                size="small"
                                // style={{ marginLeft: 16 }}
                                onClick={() => reject(params.row.ID)}
                            >
                                Reject
                            </Button>
                        </strong>
                    </>
                )}
                <strong>
                    <Button
                        disabled={params.row.Status === "Transferred"}
                        variant="contained"
                        color="secondary"
                        size="small"
                        sx={{ color: "white" }}
                        // style={{ marginLeft: 16 }}
                        onClick={() => update(params.row.ID)}
                    >
                        Update
                    </Button>
                </strong>
            </Stack>
        );
    };

    const renderOrderButton = (params) => {
        return (
            <>
                <ReceiptIcon
                    sx={{ "&:hover": { color: "black" } }}
                    color="primary"
                    onClick={() => navigate("/orders/" + params.row.OrderID)}
                />
                {params.row.OrderID}
            </>
        );
    };
    const renderNotesButton = (params) => {
        let notesIcon;
        let rejectionIcon;
        if (params.row.Notes.length > 0) {
            notesIcon = (
                <SpeakerNotesIcon
                    onClick={() => viewNote(params.row.Notes)}
                    sx={{ "&:hover": { color: "black" } }}
                    color="primary"
                />
            );
        }
        if (params.row.RejectionID != null) {
            rejectionIcon = (
                <ThumbDownIcon
                    onClick={() => {
                        setRejection(params.row.Rejection);
                        setViewRejection(true);
                    }}
                    sx={{ "&:hover": { color: "black" } }}
                    color="error"
                />
            );
        }

        return (
            <>
                {notesIcon}
                {rejectionIcon}
            </>
        );
    };

    const columns = [
        {
            field: "Order",
            headerName: "Order",
            width: 105,
            type: "number",
            renderCell: renderOrderButton,
            valueGetter: (row) => row.row?.OrderID
        },
        {
            field: "Notes",
            headerName: "Notes",
            width: 75,
            renderCell: renderNotesButton,
        },
        { field: "Barcode", headerName: "Barcode", width: 175 },
        { field: "Patient", headerName: "Patient", width: 175, valueGetter: (params) => params.row?.Order?.Patient?.LastName + ", " + params.row?.Order?.Patient?.FirstName },
        {
            field: "Patient DOB", headerName: "Date of Birth", width: 175,
            valueGetter: (params) => params?.row?.Order?.Patient && params.row?.Order?.Patient?.DOB.slice(0, 10),
        },
        {
            field: "SpecimenType",
            headerName: "Specimen",
            width: 120,
            valueGetter: (row) => row.row.SpecimenType.Specimen,
        },
        {
            field: "Status",
            headerName: "Status",
            width: 120,
            renderCell: (params) => GetSampleStatusChip(params.row?.Status),
        },
        {
            field: "Fasting",
            headerName: "Fasting",
            width: 80,
            renderCell: getCheckbox,
        },
        {
            field: "DateCollected",
            headerName: "Date Collected",
            width: 120,
            valueGetter: (row) => FormatDate(row.row.DateCollected),
        },
        {
            field: "DateReceived",
            headerName: "Date Received",
            width: 120,
            valueGetter: (row) => FormatDate(row.row.DateReceived),
        },
        {
            field: "Shipment",
            headerName: "Shipment",
            width: 120,
            renderCell: getShipment,
        },
        {
            field: "Transferred",
            headerName: "Transferred",
            width: 120,
            renderCell: getCheckbox,
        },
        {
            field: "Action",
            headerName: "Action",
            width: 300,
            renderCell: renderButtons,
        },
    ];

    return (
        <React.Fragment>
            <ViewRejectionDialog
                open={viewRejection}
                handleClose={() => setViewRejection(false)}
                rejection={rejection}
            />
            <DataGridPro
                loading={loading}
                getRowId={(row) => row.ID}
                columnVisibilityModel={{
                    Action: actions,
                }}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: "DateReceived", sort: "desc" },
                            { field: "OrderID", sort: "desc" },
                        ],
                    },
                }}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onRowSelectionModelChange={setSamples}
                rowSelectionModel={samples}
                pageSize={100}
                pagination
                checkboxSelection={true}
                rows={allSamples}
                columns={columns}
            />
        </React.Fragment>
    );
}
