import React, { useEffect, useState, useContext } from "react";
import { SessionContext } from "../hooks/getContext";
import {
    Table,
    TableCell,
    TableRow,
    TableHead,
    Typography,
} from "@mui/material";
import { getShipment } from "../services/shipments";
import { getSpecimenTypes } from "../services/specimentypes";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { rejectSample, restoreSample, updateSample } from "../services/samples";
import SpecimenTypeTable from "../tables/SpecimenTypes";
import Box from "@mui/material/Box";
import ConfirmDialog from "./ConfirmDialog";
import { receiveShipment } from "../services/shipments";
import { useNavigate } from "react-router";
import RejectionDialog from "./RejectionDialog";
import { useParams } from "react-router";
import { formatAddress, FormatDateTime } from "../services/util";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Framework from "../components/Framework";
import { GetSampleStatusChip } from "./StatusChips";
import { apiFetch } from "../services/fetch";
import { FormatDateUTC } from "../services/util";
import UpdateSampleDialog from "./UpdateSampleDialog";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export default function ShipmentView() {
    const [shipment, setShipment] = useState({ Samples: [] });
    const session = useContext(SessionContext);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [sample, setSample] = useState({});
    const [action, setAction] = useState("");
    const [refresh, setRefresh] = useState(false);
    const [specimenTypes, setSpecimenTypes] = useState([]);
    const [newSpecimenType, setNewSpecimenType] = useState([]);
    const [rejectionNotes, setRejectionNotes] = useState("");
    const [confirm, setConfirm] = useState(false);
    const navigate = useNavigate();
    const { shipmentID } = useParams();
    const [error, setError] = useState(null);

    useEffect(() => {
        let mounted = true;
        getShipment(shipmentID).then((p) => {
            if (mounted) {
                setShipment(p);
            }
        });
        getSpecimenTypes()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load Specimen Types");
                }
                return p.json();
            })
            .then((p) => {
                setSpecimenTypes(p);
            })
            .catch((e) => setError(e.message));
    }, [shipmentID, open, refresh]);

    const handleSampleButton = (action, row) => {
        setAction(action);
        setSample(row);
        setOpen(true);
    };

    const handleReceiveShipment = () => {
        setAction("receive");
        setOpen(true);
    };

    const ReceiveShipment = (shipmentID) => {
        receiveShipment(shipmentID)
            .then(() => navigate("/shipments"));
    }

    const getActions = (v) => {
        if (!edit) {
            return;
        } else if (v.row.Status !== "Rejected") {
            return (
                <>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                handleSampleButton("fix", v.row);
                            }}
                        >
                            Fix
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            size="small"
                            onClick={() => {
                                handleSampleButton("remove", v.row);
                            }}
                        >
                            Remove
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => {
                                handleSampleButton("reject", v.row);
                            }}
                        >
                            Reject
                        </Button>
                    </Stack>
                </>
            );
        } else {
            return (
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                >
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => {
                            handleSampleButton("restore", v.row);
                            handleSave();
                        }}
                    >
                        Restore
                    </Button>
                </Stack>
            );
        }
    };

    const renderOrderButton = (params) => {
        return (
            <ReceiptIcon
                sx={{ "&:hover": { color: "black" } }}
                color="primary"
                onClick={() => navigate("/orders/" + params.row.OrderID)}
            />
        );
    };

    const columns = [
        {
            field: "Actions",
            headerName: "Actions",
            width: 250,
            renderCell: getActions,
        },
        {
            field: "OrderID",
            headerName: "Order ID",
            width: 75,
        },
        {
            field: "Order",
            headerName: "View Order",
            width: 105,
            renderCell: renderOrderButton,
        },
        { field: "Barcode", headerName: "Barcode", width: 122 },
        { field: "Patient", headerName: "Patient", width: 122, valueGetter: (params) => params?.row?.Order?.Patient?.LastName + ", " + params?.row?.Order?.Patient?.FirstName },
        {
            field: "PatientDOB", headerName: "Patient DOB", width: 122, type: "date",
            valueGetter: (params) => dayjs.utc(params?.row?.Order?.Patient?.DOB).toDate(),
            valueFormatter: ({ value }) => { return value && FormatDateUTC(value) },
        },
        { field: "Facility", headerName: "Facility", width: 122, valueGetter: (params) => params?.row?.Order?.Facility?.Name },
        {
            field: "SpecimenType.Container",
            headerName: "Container",
            width: 145,
            valueGetter: (cell) => {
                return cell.row.SpecimenType.Container;
            },
        },
        {
            field: "SpecimenType.Specimen",
            headerName: "Specimen",
            width: 125,
            valueGetter: (cell) => {
                return cell.row.SpecimenType.Specimen;
            },
        },
        {
            field: "SpecimenType.Directions",
            headerName: "Directions",
            width: 175,
            valueGetter: (cell) => {
                return cell.row.SpecimenType.Directions;
            },
        },
        { field: "Status", headerName: "Status", width: 100, renderCell: (params) => GetSampleStatusChip(params.row?.Status) },
        {
            field: "DateCollected",
            headerName: "Collected On",
            width: 120,
            valueGetter: (row) => FormatDateTime(row.row.DateCollected),
        },
        {
            field: "DateReceived",
            headerName: "Received On",
            width: 140,
            valueGetter: (row) => FormatDateTime(row.row.DateReceived),
        },
    ];

    const removeSample = (sampleID) => {
        // removes sample from shipment
        const remove = async () => {
            try {
                await apiFetch(`/samples/${sampleID}/shipments/${shipmentID}`, 'DELETE')

                // if this is the last sample, redirect the user to the shipments page
                // as removing all samples prevents the shipment from being viewed due
                // to the db inner join
                if (shipment.Samples.length === 1) {
                    navigate("/shipments")
                } else {
                    setRefresh(!refresh)
                }
            } catch (e) {
                console.error(e)
                setError("Failed to remove sample")
            }
        }

        remove()
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        switch (action) {
            case "fix":
                console.log(newSpecimenType[0]);
                const s = sample;
                s.SpecimenTypeID = newSpecimenType[0];
                updateSample(s);
                break;
            case "reject":
                const reject = {
                    Reason: rejectionNotes,
                };
                rejectSample(sample.ID, reject);
                break;
            case "restore":
                restoreSample(sample.ID);
                break;
            case "remove":
                removeSample(sample.ID);
                return
            default:
                console.log("no action")
        }
        setOpen(false);
    };

    const saveEditButton = () => {
        if (!edit) {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: "100px" }}
                    onClick={() => setEdit(true)}
                    disabled={shipment?.Destination?.Reference} >
                    Edit
                </Button>
            );
        } else {
            return (
                <Button
                    variant="contained"
                    color="success"
                    sx={{ width: "100px" }}
                    onClick={() => setEdit(false)}
                >
                    Finish
                </Button>
            );
        }
    };

    const dialogs = () => {
        if (action === "fix") {
            return (
                <>
                    <UpdateSampleDialog
                        open={open}
                        setOpen={setOpen}
                        sampleId={sample.ID}
                        callback={(ref) => setRefresh(!ref)}

                    />
                </>
            );
        } else if (action === "reject") {
            return (
                <RejectionDialog
                    handleSave={handleSave}
                    handleClose={handleClose}
                    setRejectionNotes={setRejectionNotes}
                />
            );
        } else if (action === "restore") {
            return <></>;
        } else if (action === "remove") {
            return <>
                <ConfirmDialog
                    title="Remove sample?"
                    message={shipment.Samples.length > 1 ? "Are you sure want to remove the sample from this shipment?" : "Are you sure want to remove the sample from this shipment? As this is the only sample in this shipment, the shipment will be deleted."}
                    confirm={() => {
                        handleSave();
                        setOpen(false);
                    }}
                    cancel={() => {
                        setConfirm(false);
                        setOpen(false);
                    }}
                    open={open}
                />

            </>;
        } else if (action === "receive") {
            return (
                <>
                    <ConfirmDialog
                        title="Receive shipment?"
                        message="Are you sure?"
                        confirm={() => {
                            ReceiveShipment(shipmentID);
                            setOpen(false);
                        }}
                        cancel={() => {
                            setConfirm(false);
                            setOpen(false);
                        }}
                        open={open}
                    />
                </>
            );
        }
    };

    if (shipment.AddedBy === undefined) {
        return <></>;
    }

    return (
        <>
            <Framework name="Shipment">
                <Dialog open={open} onClose={handleClose} maxWidth="xl">
                    {dialogs()}
                </Dialog>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Shipment ID</TableCell>
                            <TableCell>{shipment.ID}</TableCell>
                            <TableCell>Tracking #</TableCell>
                            <TableCell>{shipment.Tracking}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Shipped At</TableCell>
                            <TableCell>
                                {FormatDateTime(shipment.DateShipped)}
                            </TableCell>
                            <TableCell>Received At</TableCell>
                            <TableCell>
                                {FormatDateTime(shipment.DateReceived)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Created By</TableCell>
                            <TableCell>{shipment.AddedBy.Email}</TableCell>
                            <TableCell>Received By</TableCell>
                            <TableCell>{shipment.ReceivedBy.Email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Mileage</TableCell>
                            <TableCell>
                                {shipment.StartMileage !== null
                                    ? shipment.EndMileage -
                                    shipment.StartMileage +
                                    " miles"
                                    : "N/A"}
                            </TableCell>
                            <TableCell>Courier</TableCell>
                            <TableCell>{shipment.Courier}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Destination</TableCell>
                            <TableCell>{shipment?.Destination?.Name}</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>
                                {formatAddress(
                                    shipment?.Destination?.Address?.Address1,
                                    shipment?.Destination?.Address?.Address2,
                                    shipment?.Destination?.Address?.City,
                                    shipment?.Destination?.Address?.State,
                                    shipment?.Destination?.Address?.ZipCode
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Box sx={{ m: 1 }}>
                    <Typography variant="h6">Samples</Typography>
                    <DataGridPro
                        columnVisibilityModel={{
                            Actions: edit,
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'OrderID', sort: 'desc' }],
                            },
                        }}
                        hideFooterRowCount
                        getRowId={(row) => row.ID}
                        density="compact"
                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {
                                    debounceMs: 500,
                                },
                            },
                        }}
                        autoHeight={true}
                        rows={shipment.Samples}
                        columns={columns}
                    />
                </Box>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ m: 2 }}
                    justifyContent="space-between"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ width: "100px" }}
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                    <Box>
                        {saveEditButton()}
                        {!!edit ? (
                            <></>
                        ) : (
                            <Button
                                variant="contained"
                                color="success"
                                disabled={shipment.Status === "Received" || shipment?.Destination?.Reference || session?.user?.Type !== "Lab"}
                                sx={{ width: "100px", mx: 2 }}
                                onClick={handleReceiveShipment}
                            >
                                Receive
                            </Button>
                        )}
                    </Box>
                </Stack>
            </Framework>
        </>
    );
}
