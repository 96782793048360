import { Button, Dialog, DialogActions, DialogContent, Box, TextField, Stack, Input, Typography, Divider, InputLabel, FormLabel, Checkbox, FormHelperText, FormGroup, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { SessionContext } from "../hooks/getContext";
import SignatureCanvas from 'react-signature-canvas';
import Title from "./Title";
import ReactSignatureCanvas from "react-signature-canvas";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";
import { User } from "../constants/types";
import DataTable from "./DataTable";

// Can be called via a facility user that's a provider OR
// an admin user via the providers panel
//
type SignatureSetupProps = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    providerId?: number,
}

function SignatureSetup({ open, setOpen, providerId }: SignatureSetupProps) {
    const session = useContext(SessionContext);
    const [loading, setLoading] = useState(false);
    const [sig, setSig] = useState("")
    const [signatureName, setSignatureName] = useState("")
    const [autoAssign, setAutoAssign] = useState(false)
    const [signatureImage, setSignatureImage] = useState<File | null>(null);
    const [delegatedSignees, setDelegatedSignees] = useState<number[]>([])
    const [availableDelegates, setAvailableDelegates] = useState<Array<User>>([])
    const [applyToExisting, setApplyToExisting] = useState(false)
    const [error, setError] = useState<null | string>(null)
    const ref = useRef<ReactSignatureCanvas | null>(null)

    const provider = session?.user?.IsProvider ? session?.user?.ProviderID : providerId
    const isLabUser = Boolean(providerId)

    useEffect(() => {
        if (!provider || !open) {
            return
        }

        const init = async () => {
            try {
                const response = await apiFetch(`/providers/${provider}/signature`, 'GET')
                if (ref.current != null) {
                    console.log('setting sig to: ', response.SignatureImg)
                    ref.current.fromDataURL(response?.SignatureImg)
                }
                setSig(response?.SignatureImg)
                setSignatureName(response?.SignatureName)
                setDelegatedSignees(response?.DelegatedSignees)
                setAvailableDelegates(response?.AvailableDelegates)
                setError(null)
            } catch (e) {
                console.log(e)
                setError("Failed to load signature details")
            }
        }

        init()
    }, [provider, open])

    useEffect(() => {
        if (signatureImage) {
            const reader = new FileReader();

            // Setup the onload event handler
            reader.onload = () => {
                const imgUrl = reader.result; // This now holds your base64 image data
                if (typeof imgUrl === 'string' && ref.current) {
                    ref.current.fromDataURL(imgUrl);
                    setSig(imgUrl)
                } else {
                    console.log('invalid type received')
                }
            };

            // Setup the onerror event handler
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };

            // Read the file as Data URL
            reader.readAsDataURL(signatureImage);

        }
    }, [signatureImage]);


    const handleSave = async () => {
        setLoading(true)
        if (!provider) {
            return
        }

        const data = {
            SignatureImg: sig,
            SignatureName: signatureName,
            DelegatedSignees: delegatedSignees,
            AutoAssign: autoAssign,
        }
        try {
            /* @ts-ignore */
            await apiFetch(`/providers/${provider}/signature`, 'POST', data)

            setOpen(false)
            if (autoAssign) {
                setApplyToExisting(true)
            }
        } catch (e) {
            setError("Failed to save signature setup")
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "FirstName", headerName: "First Name", width: 100 },
        { field: "LastName", headerName: "Last Name", width: 100 },
        { field: "Email", headerName: "Email", width: 100 },
    ]

    const instructions = isLabUser ?
        <FormLabel>Please provide the name and signature above. It will be displayed on orders that are signed by the provider or the designated signees.</FormLabel> :
        <p>Please provide your name and signature above. It will be displayed on orders that are signed by you or your designated signees.</p>

    const formIsValid = sig && signatureName

    return (
        <>
            <ApplyToExistingDiaglog open={applyToExisting} setOpen={setApplyToExisting} providerId={provider} />
            <Dialog open={open}>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Title>Signature Setup</Title>
                        <SignatureCanvas
                            ref={(r) => ref.current = r}
                            backgroundColor={'rgb(176, 176, 176)'}
                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                            onEnd={() => {
                                if (ref.current) {
                                    setSig(ref.current.toDataURL('image/png'))
                                }
                            }}
                        />
                        <Button onClick={() => { ref.current?.clear() }}>Clear</Button>
                        <Input
                            type="file"
                            inputProps={{ accept: ["image/jpeg, image/png"] }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const files = e.target.files;
                                if (files && files[0]) {
                                    setSignatureImage(files[0]);
                                } else {
                                    setSignatureImage(null);
                                }
                            }}
                        />
                        <TextField
                            value={signatureName}
                            onChange={(e) => setSignatureName(e.target.value)}
                            label="Signature Name"
                            required
                            name="SignatureName"
                            size="small"
                            variant="outlined"
                        />
                        {instructions}
                        <Divider />
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={autoAssign} onChange={(e) => setAutoAssign(e.target.checked)} />
                                    }
                                    label="Auto Apply Signature"
                                />
                            </FormGroup>
                            <FormHelperText>Enable this option to automatically apply the provider's signature to lab orders when an attestation form is available.</FormHelperText>
                        </FormControl>

                        <Divider />
                        {availableDelegates.length > 0 &&
                            <FormLabel>Select designated signees below. You are authorizing the selected users to apply this signature.</FormLabel>}
                        <Box sx={{ maxHeight: "400px" }}>
                            {availableDelegates.length > 0 &&
                                <>
                                    {/* @ts-ignore */}
                                    <DataTable
                                        selected={delegatedSignees}
                                        setSelected={setDelegatedSignees}
                                        data={availableDelegates}
                                        columns={columns}
                                        components={{}}
                                        componentProps={{}}
                                        checkboxSelection={true}
                                        hideFooter
                                    /></>}
                        </Box>
                        <ErrorAlert error={error} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} >Cancel</Button>
                    <Button disabled={loading || !formIsValid} onClick={handleSave} >Save</Button>
                </DialogActions>
            </Dialog>
        </>)



}

type ApplyToExistingDiaglogProps = {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    providerId?: number | null,
}

function ApplyToExistingDiaglog({ open, setOpen, providerId }: ApplyToExistingDiaglogProps) {
    const [apply, setApply] = useState(false)

    const handleSave = async () => {
        if (!providerId || !apply) {
            setOpen(false)
            return
        }

        try {
            await apiFetch(`/providers/${providerId}/signature/apply`, 'POST', null)
        } catch (e) {
            console.log('Failed to apply signature to existing orders', e)
        } finally {
            setOpen(false)
        }
    }

    return (
        <>
            <Dialog open={open}>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <FormLabel>Do you want to apply this signature to all existing orders for this provider?</FormLabel>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={apply}
                                name="radio-buttons-group"
                                onChange={(e) => setApply(e.target.value === 'true')}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave}>Finish</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SignatureSetup;


