import React, { useState, useEffect } from "react";
import UsersTable from "../components/UsersTable";
import {
    getUser,
    getUsers,
    deactivateUser,
    reactivateUser,
} from "../services/users";
import { Button, Snackbar, Alert } from "@mui/material";
import UserInput from "../components/UserInput";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";

export default function UserSetup() {
    const newUser = {
        IsActive: false,
        Email: "",
        FirstName: "",
        LastName: "",
        LinkToProvider: false,
        LinkToCollector: false,
        Type: "Facility",
        LaboratoryID: 1,
        Facilities: [],
        Roles: [],
        Notifications: [],
    };
    const [initialValues, setInitialValues] = useState(newUser);
    const [id, setId] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [alert, setAlert] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [refresh, setRefresh] = useState(false);

    const reset = () => {
        setRefresh(!refresh);
        setEditMode(false);
        setCreateMode(false);
    };

    useEffect(() => {
        let ignore = false

        if (ignore) {
            return
        }

        if (createMode) {
            setInitialValues(newUser);
            return
        }

        if (id.length === 1) {
            getUser(id[0])
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load user");
                    }
                    return p.json();
                })
                .then((p) => {
                    if (p?.ProviderID) {
                        p.LinkToProvider = true
                    }
                    if (p?.CollectorID) {
                        p.LinkToCollector = true
                    }
                    setInitialValues({ ...p })
                })
                .catch((e) => setError(e.message));
        }

        return () => {
            ignore = true;
        }
    }, [id, createMode, editMode]);

    if (editMode || createMode) {
        return <UserInput initialValues={initialValues} reset={reset} />;
    }

    const actUser = () => {
        reactivateUser(id[0])
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to deactivate user.");
                }
                setAlert("User activated.");
                setError(undefined);
                setRefresh(!refresh);
            })
            .catch((e) => setError(e.message));
    };

    const deactUser = () => {
        deactivateUser(id[0])
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to deactivate user.");
                }
                setAlert("User deactivated.");
                setError(undefined);
                setRefresh(!refresh);
            })
            .catch((e) => setError(e.message));
    };

    return (
        <>
            <TableFrame>
                <DataTableContainer>
                    <UsersTable
                        getUsers={getUsers}
                        setAlert={setAlert}
                        refresh={refresh}
                        setError={setError}
                        title=""
                        selectedUser={id}
                        setSelectedUser={setId}
                    />
                </DataTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setInitialValues(newUser);
                            setCreateMode(true);
                        }}
                    >
                        Create
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={id.length === 0}
                        onClick={() => setEditMode(true)}
                    >
                        Edit
                    </Button>
                    {initialValues?.IsActive ? (
                        <Button
                            variant="contained"
                            disabled={
                                id.length === 0 ||
                                initialValues?.IsActive === false
                            }
                            onClick={deactUser}
                            color="error"
                        >
                            Deactivate
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            disabled={
                                id.length === 0 ||
                                initialValues?.IsActive === true
                            }
                            onClick={actUser}
                            color="success"
                        >
                            Activate
                        </Button>
                    )}
                </ButtonTray>
            </TableFrame >
            <Snackbar open={error} onClose={() => setError(null)}>
                <Alert
                    severity="info"
                    onClose={() => setError(null)}
                    width="75%"
                    sx={{ m: 2 }}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar open={alert} onClose={() => setAlert(null)}>
                <Alert
                    severity="info"
                    onClose={() => setAlert(null)}
                    width="75%"
                    sx={{ m: 2 }}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    {alert}
                </Alert>
            </Snackbar>
        </>
    );
}
