import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FacilitySetup from "../components/FacilitySetup";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProviderSetup from "../components/ProviderSetup";
import ContactSetup from "../components/ContactSetup";
import CollectorSetup from "../components/CollectorSetup";
import UserSetup from "../components/UserSetup";
import PatientOrderLinkSetup from "../components/PatientOrderLinkSetup";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import ProviderNoteTriggerSetup from "../components/ProviderNoteTriggerSetup";
import FacilityReportServiceMappings from "../tables/facilityservicereports";

export default function Facilities() {
    const [tabValue, setTabValue] = useTabState(0)

    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };
    return (
        <Framework>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Facilities</Typography>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                    }}
                >
                    <Tabs
                        value={tabValue}
                        variant="scrollable"
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Facilities" {...a11yProps(0)} />
                        <Tab label="Providers" {...a11yProps(1)} />
                        <Tab label="Contacts" {...a11yProps(2)} />
                        <Tab label="Collectors" {...a11yProps(3)} />
                        <Tab label="Users" {...a11yProps(4)} />
                        <Tab label="Patient Order Links" {...a11yProps(5)} />
                        <Tab label="Provider Note Triggers" {...a11yProps(6)} />
                        <Tab label="Facility Report Services" {...a11yProps(7)} />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel value={tabValue} index={0}>
                {<FacilitySetup />}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {<ProviderSetup />}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                {<ContactSetup />}
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                {<CollectorSetup />}
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                {<UserSetup />}
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
                {<PatientOrderLinkSetup />}
            </TabPanel>
            <TabPanel value={tabValue} index={6}>
                <ProviderNoteTriggerSetup />
            </TabPanel>
            <TabPanel value={tabValue} index={7}>
                <FacilityReportServiceMappings />
            </TabPanel>
        </Framework>
    );
}
