import { useState } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, TextField } from "@mui/material";

export default function RejectionDialog({handleSave, handleClose}) {
        const [rejectionNotes, setRejectionNotes] = useState("");
        return ( 
                <>
                    <DialogTitle>Reject Sample</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter rejection reason.
                        </DialogContentText>
                        <TextField
                            multiline
                            maxRows={2}
                            sx={{ minWidth: "300px" }}
                            inputProps={{ maxLength: 255 }}
                            onBlur={(v) => setRejectionNotes(v.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={() => handleSave(rejectionNotes)}>Save</Button>
                    </DialogActions>
                </>)
}
