import {
    Typography,
} from "@mui/material";
import { formatAddress, FormatDateUTC } from "../../services/util";

function Patient({ orderDetails }) {
    if (orderDetails.Patient === undefined) {
        return <></>;
    }

    return (
        <>
            <Typography variant="h6" display="block" align="left">
                {orderDetails.Patient["LastName"]},{" "}
                {orderDetails.Patient["FirstName"]} |{" "}
                {FormatDateUTC(orderDetails.Patient.DOB)}
            </Typography>
            <Typography variant="body1" display="block" align="left">
                Sex: {orderDetails?.Patient?.Sex}
            </Typography>
            <Typography variant="body1" display="block" align="left">
                Address:{" "}
                {formatAddress(
                    orderDetails?.Patient?.Address?.Address1,
                    orderDetails?.Patient?.Address?.Address2,
                    orderDetails?.Patient?.Address?.City,
                    orderDetails?.Patient?.Address?.State,
                    orderDetails?.Patient?.Address?.ZipCode
                )}
            </Typography>
            {orderDetails?.Patient?.Phone !== "" ? (
                <Typography variant="body1" display="block" align="left">
                    Phone: {orderDetails?.Patient?.Phone}
                </Typography>
            ) : null}
            {orderDetails?.Patient?.Email !== "" ? (
                <Typography variant="body1" display="block" align="left">
                    Email: {orderDetails?.Patient?.Email}
                </Typography>
            ) : null}
        </>
    );
};

export default Patient;
