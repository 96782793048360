import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";

function ServerSideDataTable({ data, selected, setSelected, columns, error, loading, queryOptions, setQueryOptions, ...props }) {

    const handlePaginationModelChange = (newPaginationModel) => {
        setQueryOptions({ ...queryOptions, pagination: newPaginationModel })
    }

    const handleSortModelChange = (sortModel) => {
        setQueryOptions({ ...queryOptions, sorts: sortModel })
    }

    const handleFilterModelChange = (filterModel) => {
        setQueryOptions({ ...queryOptions, ...filterModel })
    }


    return (
        <DataGridPro
            hideFooterRowCount
            error={error}
            loading={loading}
            getRowId={(row) => row.ID}
            density="compact"
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            rowSelectionModel={selected}
            onRowSelectionModelChange={setSelected}
            pagination
            paginationMode="server"
            paginationModel={queryOptions.pagination}
            onPaginationModelChange={handlePaginationModelChange}
            sortingMode="server"
            sortModel={queryOptions.sorts}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            filterModel={queryOptions}
            onFilterModelChange={handleFilterModelChange}
            pageSizeOptions={[25, 50, 100, 500, 1000, 3000]}
            rows={data?.Data || []}
            rowCount={data?.Count || 0}
            columns={columns}
            {...props}
        />
    )
}

export default ServerSideDataTable;
