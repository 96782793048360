import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { getEligibilityLog } from "../services/fetch";
import { FormatDateTime } from "../services/util";
import { GetEligibilityStatusChip } from "../components/StatusChips";
import PersonOutline from "@mui/icons-material/PersonOutline";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";

export default function EligibilityTable() {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        getEligibilityLog()
            .then((p) => {
                setLoading(false);
                setLogs(p);
            })
            .catch((e) => setError(e.message));
    }, []);

    const getCheckbox = (params) => {
        if (params.row?.Successful) {
            return <CheckCircleIcon color="success" />;
        }
        return <ErrorIcon color="error" />;
    };

    const renderPatientButton = (params) => {
        return (
            <Stack direction="row" spacing={2}>
                <PersonOutline
                    sx={{ "&:hover": { color: "black" } }}
                    color="primary"
                    onClick={() => navigate("/patients/" + params.row.PatientID)}
                />
                <Typography variant="body2">
                    {params?.row?.Patient?.FirstName} {params?.row?.Patient.LastName}
                </Typography>
            </Stack>
        );
    };

    const getStatus = (params) => {
        const status = params.row?.Status
        return GetEligibilityStatusChip(status === "" ? "Check Failed" : status)
    }

    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        {
            field: "CreatedAt",
            headerName: "Checked At",
            width: 175,
            valueGetter: (row) => FormatDateTime(row.row?.CreatedAt),
        },
        {
            field: "Patient",
            headerName: "Patient",
            width: 225,
            renderCell: renderPatientButton,
            valueGetter: (row) => row?.row?.Patient?.FirstName + " " + row?.row?.Patient?.LastName
        },
        {
            field: "Facility",
            headerName: "Facility",
            width: 125,
            valueGetter: (row) => row?.row?.Patient?.Facility.Name,
        },
        {
            field: "Insurance",
            headerName: "Insurance",
            width: 125,
            valueGetter: (row) => row?.row?.PatientInsurance?.Insurance?.Name,
        },
        {
            field: "Member ID",
            headerName: "MemberId",
            width: 125,
            valueGetter: (row) => row?.row?.PatientInsurance?.MemberNumber,
        },

        {
            field: "PlanName",
            headerName: "Returned Plan",
            width: 350,
            valueGetter: (row) => row?.row?.PlanName,
        },
        {
            field: "Status",
            headerName: "Status",
            width: 135,
            renderCell: getStatus,
        },
        {
            field: "Successful",
            headerName: "Success",
            width: 80,
            renderCell: getCheckbox,
        },
        { field: "Error", headerName: "Error", width: 700 },
    ];

    return (
        <>
            <DataGridPro
                getRowId={(row) => row.ID}
                columns={columns}
                rows={logs}
                error={error}
                loading={loading}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "ID", sort: "desc" }],
                    },
                }}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                    height: "95%",
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                pageSize={100}
            />
        </>
    );
}
