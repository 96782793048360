import React, { useContext } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import SettingsIcon from "@mui/icons-material/Settings";
import ScienceIcon from "@mui/icons-material/Science";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";

export const MainListItems = ({ context }) => {
    if (context.user === null) {
        return null;
    }
    const roles = context.user.Roles;

    return (
        <React.Fragment>
            {roles.includes("Admin") && (
                <>
                    <ListItemButton component={Link} to={"/"}>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                    <Divider
                        color="success"
                        sx={{ width: "60%" }}
                        variant="middle"
                    />
                </>
            )}
            <ListItemButton component={Link} to={"/orders"}>
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
            </ListItemButton>

            <ListItemButton component={Link} to={"/shipments"}>
                <ListItemIcon>
                    <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary="Shipments" />
            </ListItemButton>

            {["Admin", "Technician", "Receiver", "Collector"].some((r) =>
                roles.includes(r)
            ) ? (
                <ListItemButton component={Link} to={"/samples"}>
                    <ListItemIcon>
                        <ScienceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Samples" />
                </ListItemButton>
            ) : (
                <></>
            )}
            {[
                "Admin",
                "Technician",
                "Receiver",
                "Collector",
                "Nurse",
                "Provider",
                "Customer Service",
                "Office Staff",
            ].some((r) => roles.includes(r)) ? (
                <ListItemButton component={Link} to={"/patients"}>
                    <ListItemIcon>
                        <PersonSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Patients" />
                </ListItemButton>
            ) : (
                <></>
            )}

            {["Admin", "Technician", "Customer Service"].some((r) =>
                roles.includes(r)
            ) ? (
                <ListItemButton component={Link} to={"/criticals"}>
                    <ListItemIcon>
                        <NotificationImportantIcon />
                    </ListItemIcon>
                    <ListItemText primary="Criticals" />
                </ListItemButton>
            ) : (
                <></>
            )}
            {["Admin", "Technician"].some((r) => roles.includes(r)) ? (
                <ListItemButton component={Link} to={"/results"}>
                    <ListItemIcon>
                        <BloodtypeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Results" />
                </ListItemButton>
            ) : (
                <></>
            )}
            <Divider color="success" sx={{ width: "60%" }} variant="middle" />

            {["Admin", "Billing", "Customer Service"].some((r) =>
                roles.includes(r)
            ) ? (
                <ListItemButton component={Link} to={"/billing"}>
                    <ListItemIcon>
                        <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Billing" />
                </ListItemButton>
            ) : (
                <></>
            )}
            {["Admin", "Technician", "Customer Service"].some((r) =>
                roles.includes(r)
            ) ? (
                <ListItemButton component={Link} to={"/facilities"}>
                    <ListItemIcon>
                        <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Facilities" />
                </ListItemButton>
            ) : (
                <></>
            )}

            {["Admin", "Technician"].some((r) => roles.includes(r)) ? (
                <ListItemButton component={Link} to={"/interfaces"}>
                    <ListItemIcon>
                        <LayersIcon />
                    </ListItemIcon>
                    <ListItemText primary="Interfaces" />
                </ListItemButton>
            ) : (
                <></>
            )}

            {["Admin", "Technician"].some((r) => roles.includes(r)) ? (
                <ListItemButton component={Link} to={"/setup"}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Setup" />
                </ListItemButton>
            ) : (
                <></>
            )}

            {["Admin", "Technician"].some((r) => roles.includes(r)) ? (
                <ListItemButton component={Link} to={"/"} disabled>
                    <ListItemIcon>
                        <BarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </ListItemButton>
            ) : (
                <></>
            )}
        </React.Fragment>
    );
};

export const secondaryListItems = (
    <React.Fragment>
        <ListSubheader component="div" inset>
            Saved reports
        </ListSubheader>
        <ListItemButton>
            <ListItemIcon>
                <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Current month" />
        </ListItemButton>
    </React.Fragment>
);
