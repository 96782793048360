import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import AppBar from "../components/AppBar/AppBar";
import Copyright from "../components/Copyright";
import mdTheme from "../components/Theme";


// Define custom styles
export default function Framework(props) {
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar name={props.name} />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar />
                    <Container
                        id="main-container"
                        maxWidth={false}
                        sx={{ maxWidth: "1600px", padding: { xs: "0px", md: "16px" } }}
                    >
                        <Paper
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                minHeight: "500px",
                            }}
                            {...props}
                            margin={{ xs: 0, sm: 1, md: 2 }}
                        >
                            {props.children}
                        </Paper>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
