import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import BillingTable from "../tables/BillingTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import RejectionDialog from "../components/RejectionDialog";
import Dialog from "@mui/material/Dialog";
import { Alert } from "@mui/material";
import NotesDialog from "../components/NotesDialog";
import ViewNotesDialog from "../components/ViewNotesDialog";
import BillingExports from "../components/BillingExports";
import {
    getNotReadyBillings,
    getNoBillBillings,
    getReadyBillings,
    getBilledBillings,
} from "../services/orders";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";

export default function Billing() {
    const [value, setValue] = useTabState(0);
    const [openReject, setOpenReject] = useState(false);
    const [billings, setBillings] = useState([]);
    const [openNotes, setOpenNotes] = useState(false);
    const [viewNote, setViewNote] = useState(false);
    const [notes, setNotes] = useState([]);
    const [sampleID, setSampleID] = useState();
    const [error, setError] = useState();
    const [refresh, setRefresh] = useState(0);
    const [openRestore, setOpenRestore] = useState(false);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    // const handleAddNote = (notes) => {
    // if (notes === "") {
    // setError("Notes cannot be empty.");
    // return;
    // }

    // addSampleNote(sampleID, notes)
    // .then((p) => {
    // if (!p.ok) {
    // throw new Error("Failed to add note. Please try again.");
    // } else {
    // setError(undefined);
    // setOpenNotes(false);
    // setRefresh(refresh + 1);
    // }
    // })
    // .catch((e) => setError(e.message));
    // };

    const handleViewNotes = (notes) => {
        setNotes(notes);
        setViewNote(true);
    };

    return (
        <Framework>
            <ViewNotesDialog
                notes={notes}
                open={viewNote}
                handleClose={() => setViewNote(false)}
            />
            <Dialog
                open={openNotes}
                onClose={() => setOpenNotes(false)}
                maxWidth="xl"
            >
                <NotesDialog
                    handleClose={() => setOpenNotes(false)}
                    // handleSave={handleAddNote}
                />
                {error !== undefined ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <></>
                )}
            </Dialog>
            <Dialog
                open={openReject}
                onClose={() => setOpenReject(false)}
                maxWidth="xl"
            >
                <RejectionDialog
                    handleClose={() => setOpenReject(false)}
                    // handleSave={handleRejection}
                />
                {error !== undefined ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <></>
                )}
            </Dialog>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Billing</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                    >
                        <Tab label="Ready to Bill" {...a11yProps(0)} />
                        <Tab label="Not Ready to Bill" {...a11yProps(0)} />
                        <Tab label="No Bill" {...a11yProps(0)} />
                        <Tab label="Billed" {...a11yProps(0)} />
                        <Tab label="Exports" {...a11yProps(0)} />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <BillingTable
                    viewNote={(notes) => handleViewNotes(notes)}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    getBillings={getReadyBillings}
                    billings={billings}
                    setBillings={setBillings}
                    Title="Billing"
                    addNote={(sampleID) => {
                        setSampleID(sampleID);
                        setOpenNotes(true);
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BillingTable
                    disableExport={true}
                    viewNote={(notes) => handleViewNotes(notes)}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    getBillings={getNotReadyBillings}
                    billings={billings}
                    setBillings={setBillings}
                    Title="Billing"
                    addNote={(sampleID) => {
                        setSampleID(sampleID);
                        setOpenNotes(true);
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <BillingTable
                    disableExport={true}
                    viewNote={(notes) => handleViewNotes(notes)}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    getBillings={getNoBillBillings}
                    billings={billings}
                    setBillings={setBillings}
                    Title="Billing"
                    addNote={(sampleID) => {
                        setSampleID(sampleID);
                        setOpenNotes(true);
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <BillingTable
                    disableExport={true}
                    viewNote={(notes) => handleViewNotes(notes)}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    getBillings={getBilledBillings}
                    billings={billings}
                    setBillings={setBillings}
                    Title="Billing"
                    addNote={(sampleID) => {
                        setSampleID(sampleID);
                        setOpenNotes(true);
                    }}
                />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <BillingExports />
                </TabPanel>
        </Framework>
    );
}
