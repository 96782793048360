type IntegrationID = {
	ID: number;
	Name: string;
}

export const Integrations: IntegrationID[] = [
	{ ID: 4, Name: "Labsoft" },
	{ ID: 5, Name: "Athena" },
]

export type ReportService = {
	ID: number;
	Name: string;
}

export const ReportServices: ReportService[] = [
	{ Name: "ArkStone", ID: 2 },
	{ Name: "Coriell", ID: 3 }
]

export const ReportServiceMap: { [key: number]: string } = {
	2: "ArkStone",
	3: "Coriell"
}

export const InsuranceRelationships: string[] = [
	"Self",
	"Spouse",
	"Child",
	"Mother",
	"Father",
	"Stepparent",
	"Stepchild",
]

export type OrderStatus = string

export const OrderStatusSubmitted: OrderStatus = "Submitted"
export const OrderStatusSamplesCollected: OrderStatus = "Sample(s) Collected"
export const OrderStatusSamplesReceived: OrderStatus = "Sample(s) Received"
export const OrderStatusCompleted: OrderStatus = "Completed"
export const OrderStatusCorrecting: OrderStatus = "Correcting"
export const OrderStatusCorrected: OrderStatus = "Corrected"
export const OrderStatusRejected: OrderStatus = "Rejected"

export const OrderFinalStatuses: OrderStatus[] = [OrderStatusCompleted, OrderStatusCorrected, OrderStatusRejected]
