import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import {
    Link,
    FormControl,
    FormControlLabel,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    Typography,
    Autocomplete,
} from "@mui/material";
import { Formik, Form } from "formik";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SpecimenTypeTable from "../tables/SpecimenTypes";
import TestsTable from "./TestsTable";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import {
    getTest,
    addTest,
    getCMSAllowable,
    getTestByCode,
    updateTest,
} from "../services/tests";
import { getSpecimenTypes } from "../services/specimentypes";
import Box from "@mui/material/Box";
import { getDepartments, getLaboratories } from "../services/tests";
import { getFeeSchedules } from "../services/feeschedules";
import { financialClassIndex } from "../services/util";
import { apiFetch } from "../services/fetch";
import TestEditReportService from "./TestEditReportService";
import * as Yup from "yup";
import ErrorAlert from "./ErrorAlert";
import { CustomTextField, CustomCheckboxField } from "./CustomTextField";
import { FetchLoader } from "./FetchLoader";

function removeAt(arr, index) {
    // helper function for popping element out of array at specific index
    var j = 0;
    var arr2 = [];
    for (var i = 0; i < arr.length; i++) {
        if (i !== index) {
            arr2[j] = arr[i];
            j++;
        }
    }
    return arr2;
}

function SaveTest(test, setError, saved) {
    // if test is being edited remove self from subtest selection
    if (test.ID && test.SubTests.indexOf(test.ID)) {
        const selfIndex = test.SubTests.indexOf(test.ID);
        test.SubTests = removeAt(test.SubTests, selfIndex);
    }

    if (test.IsPanel && test.SubTests.length === 0) {
        setError("Can't save test as panel with no subtests selected.");
        return false;
    }

    const t = validationSchema.cast(test);

    if (test.ID) {
        updateTest(t, test.ID)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed");
                }
                setError("");
                saved();
                return p.json();
            })
            .catch((e) => setError(e.message));
    } else {
        addTest(t)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed");
                }
                setError("");
                saved();
                return p.json();
            })
            .catch((e) => setError(e.message));
    }
    return true;
}

const validationSchema = Yup.object({
    Code: Yup.number().required(),
    Name: Yup.string().required(),
    Description: Yup.string().required(),
    CPTCode: Yup.string(),
    LOINC: Yup.string(),
    IsPanel: Yup.bool(),
    Orderable: Yup.bool(),
    Internal: Yup.bool(),
    Control: Yup.bool(),
    Cost: Yup.number(),
    Ordering: Yup.number(),
    DepartmentID: Yup.number().required("Department is required"),
    CategoryID: Yup.number().nullable(),
    MaxTAT: Yup.number().min(1, "Max turnaround time must be greater then zero").required("Max turnaround time is required"),
    Stability: Yup.number().min(1, "Stability must be greater than zero").required("Stability is required"),
    ReportTypeID: Yup.number().min(1).max(1).required(),
    Referenced: Yup.bool(),
    ReferenceLabTestCode: Yup.string(),
    LaboratoryID: Yup.number().required("Performing laboratory is required"),
    Comment: Yup.string(),
    // only one report service can have default as true
    ReportServices: Yup.array().of(
        Yup.object().shape({
            "ReportServiceID": Yup.number().required(),
            "ReportServiceMeta": Yup.string(),
            "Default": Yup.boolean(),
        })).nullable().test("default", "Only one report service can be default", function(value) {
            if (value.filter((v) => v.Default).length > 1) {
                return false;
            }
            return true;
        }
        ).transform((currentVal) => { if (currentVal === null) { return [] } else { return currentVal } }),
    Fees: Yup.array().of(Yup.object().shape({
        FeeScheduleID: Yup.number().required(),
        Fee: Yup.number().required()
    })),
    SpecimenTypes: Yup.array().of(Yup.number().min(1)).min(1, "At least one specimen type must be selected"),
    SubTests: Yup.array().of(Yup.number()).when("IsPanel",
        { is: true, then: (schema) => schema.min(1, "At least one subtest is required") }),
})

const newTest = {
    Code: "",
    Name: "",
    Description: "",
    LOINC: "",
    CPTCode: "",
    Ordering: 1,
    Cost: 0,
    MaxTAT: 0,
    Stability: 0,
    DepartmentID: "",
    ReportTypeID: 1,
    LaboratoryID: 1,
    IsPanel: false,
    Orderable: false,
    Internal: false,
    Control: false,
    Referenced: false,
    ReferenceLabTestCode: "",
    ReportServices: [],
    SpecimenTypes: [],
    SubTests: [],
    Comment: "",
};

const initialTouched = {
    SubTests: true,
    SpecimenTypes: true,
}

export default function TestForm({
    testId,
    saved,
}) {
    const [testDetails, setTestDetails] = useState({});
    const [departments, setDepartments] = useState([]);
    const [categories, setCategories] = useState([]);
    const [laboratories, setLaboratories] = useState([]);
    const [allSpecimenTypes, setAllSpecimenTypes] = useState([]);
    const [disableNonEditable, setDisableNonEditable] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const formikRef = useRef();

    // used for duplicate checking
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(null);
    const [loincList, setLoincList] = useState([]);
    const [loincInput, setLoincInput] = useState("");
    const [allowable, setAllowable] = useState("");
    const [cptCode, setCPTCode] = useState("");

    useEffect(() => {
        if (testId) {
            getTest(testId).then((p) => {
                const specTypeIds = p.SpecimenTypes.map((t) => t.ID);
                p.SpecimenTypes = specTypeIds;

                const subTestIds = p.SubTests.map((t) => t.ID)
                p.SubTests = subTestIds;

                setTestDetails(p);
                setLoading(false);
            });
        } else {
            setTestDetails(newTest)
            setLoading(false);
        }
    }, [testId]);

    useEffect(() => {
        if (!testDetails || Object.keys(testDetails).length === 0) {
            return;
        }

        getFeeSchedules()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load fee schedules");
                }
                return p.json();
            })
            .then((p) => {
                let td = { ...testDetails };
                // initialize fee schedule values in values
                if (
                    !testDetails.hasOwnProperty("Fees") ||
                    testDetails.Fees.length === 0
                ) {
                    let fees = [];
                    for (let f of p) {
                        fees.push({
                            FeeScheduleID: f.ID,
                            Fee: 0,
                            FeeSchedule: {
                                Name: f.Name,
                                FinancialClass: f.FinancialClass,
                            },
                        });
                    }

                    td.Fees = fees;
                    setTestDetails({ ...td });
                }
            });

        if (
            testDetails.Code !== undefined &&
            testDetails.Code !== "" &&
            testDetails.ID
        ) {
            setDisableNonEditable(true);
        }
    }, [testDetails]);


    useEffect(() => {
        if (!cptCode || cptCode.length !== 5) {
            return;
        }

        getCMSAllowable(cptCode)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to lookup CMS Allowable");
                }

                return p.json();
            })
            .then((p) => setAllowable("CMS Allowable: $" + p.Rate))
            .catch((e) => console.log(e.message));
    }, [cptCode]);

    useEffect(() => {
        if (loincInput === "") {
            return;
        }
        fetch(
            `https://clinicaltables.nlm.nih.gov/api/loinc_items/v3/search?terms=${loincInput}&df=text,LOINC_NUM,AnswerLists&sf=text,LONG_COMMON_NAME,LOINC_NUM&maxList=25`
        )
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load medication list.");
                }

                return p.json();
            })
            .then((p) => {
                let loincs = p[3].map((row) => {
                    return { Description: row[0], LOINC: row[1] };
                });

                setLoincList(loincs);
            })
            .catch((e) => setError(e.message));
    }, [loincInput]);

    useEffect(() => {
        getSpecimenTypes()
            .then(p => {
                if (!p.ok) {
                    throw new Error("Failed to load specimen types")
                }
                return p.json()
            })
            .then((p) => {
                setAllSpecimenTypes(p);
            }).catch(e => setError(e.message));
        const getCategories = async () => {
            try {
                const response = await apiFetch(`/categories`)
                setCategories(response)
            } catch (e) {
                setError("Failed to load categories")
            }
        }
        getCategories()

        getDepartments()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load departments");
                }
                return p.json();
            })
            .then((p) => setDepartments(p));
        getLaboratories()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratories");
                }
                return p.json();
            })
            .then((p) => setLaboratories(p));

    }, []);

    useEffect(() => {
        if (code !== null && code !== "") {
            getTestByCode(code)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to look up test by code.");
                    }
                    return p.json();
                })
                .then((p) => {
                    if (p.length > 0) {
                        setCodeError("Code already exists.");
                    } else {
                        setCodeError("");
                    }
                })
                .catch((e) => setError(e.message));
        }
    }, [code]);

    if (loading) {
        return <FetchLoader isLoading={loading} error={error} />;
    }

    return (
        <Formik
            initialTouched={initialTouched}
            innerRef={formikRef}
            initialValues={testDetails}
            validationSchema={validationSchema}
            onSubmit={(v) => SaveTest(v, setError, saved)}
            enableReinitialize
        >
            {({ handleChange,
                values,
                setFieldValue,
                errors,
                touched,
                handleSubmit,
            }) => (
                <Form>
                    <Stack direction="column" spacing={2}>
                        <Stack
                            spacing={{ xs: 2, md: 2, lg: 2, xl: 2 }}
                            direction={{ md: "column", lg: "row" }}
                        >
                            <CustomTextField
                                onChange={(t) => {
                                    setCode(t.target.value);
                                    handleChange(t)
                                }}
                                disabled={disableNonEditable}
                                error={!!codeError}
                                helperText={codeError}
                                required
                                name="Code"
                                inputProps={{ type: "number", min: 1 }}
                            />
                            <CustomTextField
                                required
                                name="Name"
                            />
                            <CustomTextField
                                required
                                name="Description"
                                sx={{ width: "100%" }}
                            />
                        </Stack>
                        <Stack
                            spacing={{ xs: 2, md: 2, lg: 2, xl: 2 }}
                            direction={{ md: "column", lg: "row" }}
                            sx={{ width: "100%" }}
                        >
                            <CustomTextField
                                onChange={(e) => { setCPTCode(e.target.value); handleChange(e) }}
                                autoComplete="off"
                                name="CPTCode"
                                label="CPT Code"
                                helperText={allowable}
                            />
                            <Autocomplete
                                error={error !== null ? error : ""}
                                options={loincList}
                                // sx={{
                                // minWidth: "223px",
                                // maxWidth: "100%",
                                // }}
                                getOptionLabel={(option) => {
                                    if (option?.LOINC === undefined) {
                                        return option;
                                    }
                                    return option.LOINC + " - " + option.Description;
                                }}
                                keepNonExistentRowsSelected
                                filterOptions={(x) => x}
                                isOptionEqualToValue={(option, value) => {
                                    return option.LOINC === value;
                                }}
                                value={values?.LOINC || ""}
                                onChange={(_, v) => {
                                    setFieldValue("LOINC", v.LOINC);
                                    if (values?.Description === "") {
                                        setFieldValue("Description", v.Description)
                                    }
                                }}
                                sx={{ width: "100%" }}
                                name="LOINC"
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            size="small"
                                            defaultValue={values?.LOINC}
                                            onChange={(e) =>
                                                setLoincInput(e.target.value)
                                            }
                                            label="LOINC"
                                            helperText={
                                                values?.LOINC !== undefined ? (
                                                    <Link
                                                        href={`https://loinc.org/${values?.LOINC}`}
                                                        target="_blank"
                                                    >{`https://loinc.org/${values?.LOINC}`}</Link>
                                                ) : null
                                            }
                                        />
                                    );
                                }}
                            />
                        </Stack>
                        <Stack
                            direction={{ sm: "column", md: "row" }}
                            spacing={{ xs: 2, md: 2, lg: 2, xl: 2 }}
                            alignItems="flex-start"
                        >
                            <CustomCheckboxField
                                name="Orderable"
                            />
                            <CustomCheckboxField
                                name="Internal"
                            />
                            <CustomCheckboxField
                                name="Control"
                            />
                            <CustomCheckboxField
                                name="IsPanel"
                                label="Is Panel"
                            />
                        </Stack>
                        <Stack
                            direction={{ sm: "column", md: "row" }}
                            spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                        >
                            <CustomTextField
                                required
                                inputProps={{ type: "number" }}
                                name="MaxTAT"
                                label="Max Turnaround Time (hr)"
                            />
                            <CustomTextField
                                required
                                inputProps={{ type: "number" }}
                                name="Stability"
                                label="Stability (hr)"
                            />
                            <FormControl sx={{ minWidth: 200, m: 1 }} size="small">
                                <TextField
                                    size="small"
                                    select
                                    required
                                    label="Performing Laboratory"
                                    value={
                                        values.LaboratoryID === undefined
                                            ? ""
                                            : values.LaboratoryID
                                    }
                                    name="LaboratoryID"
                                    key="Performing Laboratory"
                                    onChange={(v) =>
                                        setFieldValue('LaboratoryID', v.target.value)
                                    }
                                >
                                    {laboratories.map((option) => (
                                        <MenuItem key={option.ID} value={option.ID}>
                                            {option.Name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <CustomTextField
                                required={values.LaboratoryID !== 1}
                                disabled={values.LaboratoryID === 1}
                                name="ReferenceLabTestCode"
                                label="Reference Lab Test Code"
                            />
                        </Stack>
                        <Typography variant="h6">Result Report Options</Typography>
                        <Stack
                            direction={{ sm: "column", md: "row" }}
                            spacing={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                        >
                            <CustomTextField
                                inputProps={{ type: "number" }}
                                name="Ordering"
                                label="Report Priority"
                                required
                            />
                            <CustomTextField
                                name="Comment"
                            />
                            <TextField
                                sx={{ minWidth: "200px" }}
                                select
                                size="small"
                                required
                                value={
                                    values.ReportTypeID === undefined
                                        ? ""
                                        : values.ReportTypeID
                                }
                                name="ReportTypeID"
                                label="Report Type"
                                onChange={(v) =>
                                    setFieldValue('ReportTypeID', v.target.value)
                                }
                            >
                                <MenuItem key={1} value={1}>
                                    Default
                                </MenuItem>
                            </TextField>
                        </Stack>
                        <TestEditReportService />
                        <Typography variant="h6">Financial Reporting</Typography>
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <CustomTextField
                                onChange={(t) => {
                                    t.target.value = parseInt(t.target.value * 100);
                                    handleChange(t);
                                }}
                                value={values.Cost / 100}
                                inputProps={{ type: "number", min: 0, step: 0.01 }}
                                name="Cost"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                required
                                select
                                size="small"
                                value={values.DepartmentID || ""}
                                name="DepartmentID"
                                helperText={touched?.['DepartmentID'] && String(errors?.['DepartmentID'] || "")}
                                error={touched?.['DepartmentID'] && Boolean(errors?.['DepartmentID'])}
                                label="Department"
                                sx={{ minWidth: "200px" }}
                                onChange={(v) => handleChange(v)}
                            >
                                {departments.map((option) => (
                                    <MenuItem key={option.ID} value={option.ID}>
                                        {option.Name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                size="small"
                                value={values.CategoryID || ""}
                                helperText={touched?.['Category'] && String(errors?.['Category'] || "")}
                                error={touched?.['Category'] && Boolean(errors?.['Category'])}
                                name="CategoryID"
                                label="Category"
                                sx={{ minWidth: "200px" }}
                                onChange={(v) => handleChange(v)}
                            >
                                {categories.map((option) => (
                                    <MenuItem key={option.ID} value={option.ID}>
                                        {option.Name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Stack>
                        <Table>
                            <TableHead>
                                <TableRow height="20px">
                                    <TableCell height="80%">Fee Schedule</TableCell>
                                    <TableCell height="80%">Financial Class</TableCell>
                                    <TableCell height="80%">Fee</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {values?.Fees && values.Fees.map((fs, i) => {
                                    return (
                                        <TableRow height="20px">
                                            <TableCell>{fs.FeeSchedule.Name}</TableCell>
                                            <TableCell>
                                                {financialClassIndex[fs.FeeSchedule.FinancialClass]}
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={fs.FeeSchedule.Name}
                                                    label={fs.FeeSchedule.Name}
                                                    required
                                                    size="small"
                                                    inputProps={{
                                                        type: "number",
                                                        min: 0,
                                                        step: 0.01,
                                                    }}
                                                    defaultValue={
                                                        values.Fees[i].Fee / 100
                                                    }
                                                    onBlur={(v) => {
                                                        let td = { ...values };
                                                        td.Fees[i].FeeScheduleID =
                                                            fs.FeeScheduleID;
                                                        td.Fees[i].Fee = parseInt(
                                                            v.target.value * 100
                                                        );
                                                        setFieldValue('Fees', td.Fees);
                                                    }}
                                                    InputProps={{
                                                        required: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                $
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                ></TextField>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>

                        <Stack direction="column" spacing={2}>
                            <Typography variant="h6">Specimen Types</Typography>
                            <Box sx={{ height: "320px" }}>
                                <SpecimenTypeTable
                                    specimenTypes={values.SpecimenTypes}
                                    setSpecimenTypes={(v) => {
                                        setFieldValue("SpecimenTypes", v);
                                    }}
                                    allSpecimenTypes={allSpecimenTypes}
                                    checkboxs={true}
                                    sx={{ width: "100%" }}
                                    showPriority={true}
                                />
                            </Box>
                            {values?.IsPanel && (
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6">Subtests</Typography>
                                    <Box sx={{ height: "340px" }}>
                                        <TestsTable
                                            test={values.SubTests || []}
                                            setTest={(v) => {
                                                setFieldValue('SubTests', v);
                                            }}
                                            disableAutoHeight={true}
                                            checkboxes={true}
                                            noPanels={true}
                                            withPriority={true}
                                        />
                                    </Box>
                                </Stack>
                            )}
                            {
                                // display errors that are in errors and touched
                                Object.keys(errors).filter((key) => touched[key]).map((key) =>
                                    <ErrorAlert error={errors[key]} />
                                )}
                            <ErrorAlert error={error} />
                            <Stack
                                width="100%"
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={saved}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}
