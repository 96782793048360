import React, { useState, useContext } from "react";
import { Button, Box, Stack, Typography } from "@mui/material";
import OrdersTable from "../components/OrdersTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import PatientOrders from "../components/PatientOrders";
import { DataTableContainerNoButton } from "../components/TableContainer";
import BulkResultUpload from "../components/BulkResultUpload";
import mdTheme from "../components/Theme";
import { useMediaQuery } from "@mui/material";
import { SessionContext } from "../hooks/getContext";
import BulkEdit from "../components/BulkEdit";

export default function Orders() {
    const [value, setValue] = useTabState(0)
    const [showUpload, setShowUpload] = useState(false)
    const [showBulkEdit, setShowBulkEdit] = useState(false)
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));
    const session = useContext(SessionContext);

    return (
        <Framework>
            <BulkResultUpload open={showUpload} setOpen={setShowUpload} />
            <BulkEdit open={showBulkEdit} setOpen={setShowBulkEdit} />
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Orders</Typography>

                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Tabs value={value} onChange={(_, v) => setValue(v)} variant="scrollable">
                            <Tab label="Pending" {...a11yProps(0)} />
                            <Tab label="Submitted" {...a11yProps(1)} />
                            <Tab label="Completed" {...a11yProps(2)} />
                            <Tab label="All" {...a11yProps(3)} />
                            <Tab label="Requires Review" {...a11yProps(4)} />
                            <Tab label="Deleted" {...a11yProps(5)} />
                        </Tabs>
                        <Stack direction="row" justifyContent="right" alignItems="center" spacing={2}>
                            {!isMobile && session.user.Roles.includes("Admin") && session.user.Type === "Lab" && <Button
                                onClick={() => setShowBulkEdit(true)}
                                variant="contained"
                                color="primary"
                                sx={{ maxHeight: "35px", minWidth: "100px" }}
                                size="small"
                            >
                                Bulk Edit
                            </Button>}
                            {!isMobile && session.user.Type === "Lab" && <Button
                                onClick={() => setShowUpload(true)}
                                variant="contained"
                                color="primary"
                                sx={{ maxHeight: "35px", minWidth: "140px" }}
                                size="small"
                            >
                                Result Upload
                            </Button>}
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <DataTableContainerNoButton>
                    <OrdersTable
                        setOrder={() => { }}
                        title="Pending Orders"
                        ordersURL="/orders?filter=Submitted,Sample(s) Collected,Sample(s) Received"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DataTableContainerNoButton>
                    <OrdersTable
                        showRequestedCollectionDate={true}
                        setOrder={() => { }}
                        title="Submitted Orders"
                        ordersURL="/orders?filter=Submitted"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DataTableContainerNoButton>
                    <OrdersTable
                        setOrder={() => { }}
                        title="Completed Orders"
                        ordersURL="/orders?filter=Completed"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DataTableContainerNoButton>
                    <OrdersTable
                        setOrder={() => { }}
                        title="All Orders"
                        ordersURL="/orders"
                    />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <DataTableContainerNoButton>
                    <PatientOrders />
                </DataTableContainerNoButton>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <DataTableContainerNoButton>
                    <OrdersTable
                        setOrder={() => { }}
                        title="Deleted Orders"
                        ordersURL="/orders?filter=Deleted"
                        deletedColumns={true}
                    />
                </DataTableContainerNoButton>
            </TabPanel>
        </Framework >
    );
}
