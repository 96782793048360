import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Button,
    Dialog,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { FormatDateTime } from "../services/util";

export default function ViewRejectionDialog({ rejection, handleClose, open }) {
    return (
        <Dialog open={open}>
            <DialogTitle>Rejection</DialogTitle>
            <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Reason</TableCell>
                                <TableCell>Date Rejected</TableCell>
                                <TableCell>Rejected By</TableCell>
                            </TableRow>
                                    <TableRow key={rejection?.ID}>
                                        <TableCell>{rejection?.Reason}</TableCell>
                                        <TableCell>
                                            {FormatDateTime(rejection?.CreatedAt)}
                                        </TableCell>
                                        <TableCell>
                                            {rejection?.RejectedBy?.Email}
                                        </TableCell>
                                    </TableRow>
                        </TableHead>
                    </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
