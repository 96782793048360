import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { getTests } from "../services/tests";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatFee, HoursToHumanReadable } from "../services/util";

export default function TestsTable({
    test,
    setTest,
    checkboxes,
    noPanels,
    refresh,
    withPriority
}) {
    const [loading, setLoading] = useState(true);
    const [allTests, setAllTests] = useState([]);
    const apiRef = useGridApiRef();

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    useEffect(() => { apiRef.current.applySorting() }, [test])

    const getReportType = (type) => {
        if (type === 1) {
            return "Default";
        }
    };

    let columns = [
        { field: "ID", headerName: "ID", width: 50 },
        { field: "Code", headerName: "Code", width: 75 },
        { field: "Name", headerName: "Name", width: 125 },
        { field: "Description", headerName: "Description", width: 175 },
        { field: "LOINC", headerName: "LOINC", width: 100 },
        { field: "CPTCode", headerName: "CPTCode", width: 100 },
        {
            field: "IsPanel",
            headerName: "Panel",
            width: 90,
            renderCell: getCheckbox,
        },
        {
            field: "Orderable",
            headerName: "Orderable",
            width: 90,
            renderCell: getCheckbox,
        },
        {
            field: "Referenced",
            headerName: "Referenced",
            width: 90,

            renderCell: getCheckbox,
        },
        {
            field: "Internal",
            headerName: "Internal",
            width: 90,
            renderCell: getCheckbox,
        },
        {
            field: "Control",
            headerName: "Control",
            width: 90,
            renderCell: getCheckbox,
        },
        {
            field: "Cost",
            headerName: "Cost",
            width: 80,
            valueGetter: (row) => formatFee.format(row.row.Cost / 100),
        },
        {
            field: "Department",
            headerName: "Department",
            width: 120,
            valueGetter: (row) => {
                return row.row.Department.Name;
            },
        },
        {
            field: "Category",
            headerName: "Category",
            width: 120,
            valueGetter: (row) => {
                return row.row.Category.Name;
            },
        },
        {
            field: "MaxTAT",
            headerName: "MaxTAT",
            width: 100,
            valueGetter: (row) => HoursToHumanReadable(row.row.MaxTAT),
        },
        {
            field: "Stability",
            headerName: "Stability",
            width: 100,
            valueGetter: (row) => HoursToHumanReadable(row.row.Stability),
        },
        {
            field: "ReportType",
            headerName: "ReportType",
            width: 150,
            valueGetter: (row) => {
                return getReportType(row.row.ReportTypeID);
            },
        },
        {
            field: "ReferenceLabTestCode",
            headerName: "ReferenceLabTestCode",
            width: 150,
        },
        {
            field: "Laboratory.Name",
            headerName: "Laboratory",
            width: 150,
            valueGetter: (row) => {
                return row.row.Laboratory.Name;
            },
        },
        { field: "Comment", headerName: "Comment", width: 150 },
    ];

    const prioritySort = (v1, v2, param1, param2) => {
        if (!v1) return 9999
        if (!v2) return v1
        return v1 - v2
    }

    function priorityComparator(value1, value2) {
        // Check if the first value is an empty string
        if (value1 === "") {
            return 1; // Place it after the second value
        }
        // Check if the second value is an empty string
        if (value2 === "") {
            return -1; // Place it after the first value
        }
        // Convert values to numbers for comparison
        const num1 = Number(value1);
        const num2 = Number(value2);

        // Compare the numeric values
        if (num1 < num2) {
            return -1;
        }
        if (num1 > num2) {
            return 1;
        }
        return 0;
    }


    const priorityColumn = {
        field: "Priority",
        headerName: "Priority",
        width: 80,
        type: "number",
        valueGetter: (row) => {
            const i = test.indexOf(row.id);
            if (i > -1) {
                return i + 1;
            } else {
                return "";
            }
        },
        sortComparator: priorityComparator
    }

    if (withPriority) {
        const startColumns = columns.slice(0, 1)
        startColumns.push(priorityColumn)
        const endColumns = columns.slice(1)
        columns = [...startColumns, ...endColumns]
    }

    useEffect(() => {
        getTests().then((t) => {
            // FIXME update test endpoint to accept filters

            if (noPanels) {
                const filteredTests = [];
                for (let test of t) {
                    if (!test.IsPanel) {
                        filteredTests.push(test);
                    }
                }
                t = filteredTests;
            }
            setAllTests(t);
            setLoading(false);
        });
    }, [refresh]);

    let initState = {
        sorting: {
            sortModel: [{ field: "Code", sort: "asc" }],
        },
    }
    if (withPriority) {
        initState = {
            sorting: {
                sortModel: [{ field: "Priority", sort: "asc" }],
            },
        }
    }

    return (
        <DataGridPro
            getRowId={(row) => row.ID}
            apiRef={apiRef}
            density="compact"
            loading={loading}
            initialState={initState}
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            keepNonExistentRowsSelected={true}
            checkboxSelection={checkboxes}
            onRowSelectionModelChange={setTest}
            pagination
            pageSize={50}
            rowSelectionModel={test}
            // autoHeight={!disableAutoHeight}
            rows={allTests}
            columns={columns}
        />
    );
}
