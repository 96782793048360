import { useState, useCallback } from "react";
import { Box } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { eligibilityStatusIndex, FormatDateUTC } from "../services/util";
import { Alert, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { checkInsuranceEligibility } from "../services/patients";
import { GetEligibilityStatusChip } from "./StatusChips";
import { eligibilityStatus } from "../services/util";

export default function PatientInsurancesTable({
    insurances,
    setInsurances,
}) {
    const [error, setError] = useState(null);
    const [checkingElig, setCheckingElig] = useState([]);

    const checkEligibility = (patientId, patientInsId) => {
        const checking = [...checkingElig, patientInsId]
        console.log('checking', checking)
        setCheckingElig([...checkingElig, patientInsId]);

        let reason = "Manual";
        let dateOfService = new Date();

        checkInsuranceEligibility(
            patientId,
            patientInsId,
            dateOfService.toISOString(),
            reason
        )
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Unable to check eligibility at this time.");
                }
                return p.json();
            })
            .then(p => {
                //update insurance with eligibility status
                let newIns = []
                for (let i of insurances) {
                    if (i.ID === patientInsId && i.PatientID === patientId) {
                        i.EligibilityStatus = eligibilityStatus[p.Status]
                        if (p.Error !== "") {
                            i.EligibilityDetail = p.Error
                        } else {
                            i.EligibilityDetail = p.PlanName
                        }
                    }
                    newIns.push(i)
                }
                setInsurances(newIns);
            })
            .catch((e) => setError(e.message))
            .finally(() => {
                setCheckingElig((checkingElig) =>
                    checkingElig.filter((item) => item !== patientInsId)
                );
            });

    };

    console.log(checkingElig)

    const renderEligibilityButton = (params) => {
        console.log(checkingElig.includes(params.id))
        return (
            <strong>
                <LoadingButton
                    variant="contained"
                    loading={checkingElig.includes(params.id)}
                    disabled={checkingElig.includes(params.id) || params.row.Insurance?.PVerifyCode === ""}
                    loadingIndicator="Verifying..."
                    color="secondary"
                    size="small"
                    sx={{ color: "white" }}
                    // style={{ marginLeft: 16 }}
                    onClick={() => {
                        checkEligibility(params.row.PatientID, params.id);
                    }}
                >
                    Verify Eligibility
                </LoadingButton>
            </strong>
        );
    };

    const isPrimary = (primary) => {
        if (primary === true) {
            return "Primary";
        }
        return "Secondary";
    };

    const renderDetail = useCallback(row => {
        if (row.row?.EligiblityStatus) {
            return <></>;
        }

        const columns = [
            {
                field: "EligibilityStatus",
                headerName: "Eligibility Status",
                width: 140,
                renderCell: (row) => GetEligibilityStatusChip(eligibilityStatusIndex[row.row?.EligibilityStatus]),
            },
            {
                field: "EligibilityLastChecked",
                headerName: "Eligibility Last Checked",
                width: 200,
                valueGetter: (row) => FormatDateUTC(row.row.EligibilityLastChecked),
            },
            {
                field: "CheckEligibility",
                headerName: "Verify Eligibility",
                width: 200,
                renderCell: renderEligibilityButton,
            },
            {
                field: "EligibilityDetail",
                headerName: "Eligibility Detail",
                width: 600,
            },
        ]

        return <DataGridPro
            density="compact"
            getRowId={(row) => row.ID}
            columns={columns}
            rows={[row.row]}
            hideFooter />
    }, [checkingElig])


    const columns = [
        {
            field: "Name",
            headerName: "Name",
            width: 200,
            valueGetter: (row) => row.row.Insurance.Name,
        },
        {
            field: "Type",
            headerName: "Primary/Secondary",
            width: 150,
            valueGetter: (row) => isPrimary(row.row.Primary),
        },
        { field: "MemberNumber", headerName: "Member #", width: 150 },
        { field: "GroupNumber", headerName: "Group #", width: 100 },
        {
            field: "DateEffective",
            headerName: "Effective",
            width: 100,
            valueGetter: (row) => {
                return FormatDateUTC(row.row.DateEffective);
            },
        },
        {
            field: "DateDiscontinue",
            headerName: "Discontinue",
            width: 100,
            valueGetter: (row) => {
                return FormatDateUTC(row.row.DateDiscontinue);
            },
        },
        { field: "Relationship", headerName: "Relationship", width: 100 },
    ];

    return (
        <>
            <Box width="100%">
                <Stack direction="column" spacing={2}>
                    <DataGridPro
                        density="compact"
                        getRowId={(row) => row.ID}
                        autoHeight={true}
                        getDetailPanelContent={renderDetail}
                        getDetailPanelHeight={() => "auto"}
                        initialState={{ detailPanel: { expandedRowIds: insurances.map((i) => i.ID) } }}
                        rows={insurances}
                        columns={columns}
                        hideFooter
                    />
                    {error !== null ? (
                        <Alert severity="warning">{error}</Alert>
                    ) : null}
                </Stack>
            </Box>
        </>
    );
}
