import React, { useContext, useRef } from "react";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SampleLinearStepper from "../components/SampleStepper";
import OrderSamples from "../components/OrderSamples";
import { Alert } from "@mui/material";
import { addSamples } from "../services/samples";
import { getProfileDetails } from "../services/profiles";
import { getReflex } from "../services/tests";
import OrdersTable from "../components/OrdersTable";
import {
    getOrderDetails,
} from "../services/orders";
import Framework from "../components/Framework";
import { useNavigate } from "react-router";
import { addShipment, receiveShipment } from "../services/shipments";
import { getLaboratories } from "../services/tests";
import ErrorAlert from "../components/ErrorAlert";
import { SessionContext } from "../hooks/getContext";
import { validationSchema as shipmentSchema } from "./NewShipment";
import { ShipmentForm, validationSchema } from "../pages/NewShipment";
import { Formik, Form } from "formik";
import ShipmentFormFields from "../components/ShipmentFormFields";

const shipmentValues = {
    "NewOrderShipment": true,
    "Tracking": "",
    "Type": "package", // package or courier
    "Samples": [],
    "DateShipped": new Date(),
    "Courier": "",
    "StartMileage": null,
    "StopMileage": null,
    "DestinationID": null,
}


export default function NewSample() {
    const location = useLocation();
    const passedState = location.state;
    const [order, setOrder] = useState([]);
    const [orderDetails, setOrderDetails] = useState({});
    const [samples, setSamples] = useState([]);
    const [error, setError] = useState("");
    const [sampleDetails, setSampleDetails] = useState([]);
    const [step, setStep] = useState(0);
    const [collector, setCollector] = useState();
    const [samplesCreated, setSamplesCreated] = useState(false);
    const [facility, setFacility] = useState();
    const [dateCollected, setDateCollected] = useState(new Date());
    const [shipmentOption, setShipmentOption] = useState("noShipment");
    const [testDetails, setTestDetails] = useState([]);
    const [profileDetails, setProfileDetails] = useState([]);
    const [reflexDetails, setReflexDetails] = useState([]);
    const [reflexesLoaded, setReflexesLoaded] = useState(false);
    const [laboratories, setLaboratories] = useState([]);
    const [laboratory, setLaboratory] = useState({});
    const [startMil, setStartMil] = useState();
    const [endMil, setEndMil] = useState();
    const [fasting, setFasting] = useState(false);
    const navigate = useNavigate();
    const [tracking, setTracking] = useState("");
    const [courierName, setCourierName] = useState("");
    const [courier, setCourier] = useState("");
    const [dateShipped, setDateShipped] = useState(new Date());
    const session = useContext(SessionContext);
    const userRoles = session?.user?.Roles;
    const shipmentFormRef = useRef()
    const shipmentId = useRef()

    useEffect(() => {
        getLaboratories()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load laboratories.");
                }
                return p.json();
            })
            .then((p) => {
                setLaboratories(p);
                setLaboratory(p[0]);
            })
            .catch((e) => setError(e.message));
    }, []);

    useEffect(() => {
        if (!passedState) {
            return
        }

        setOrder([passedState.orderId])
        setStep(1)
    }, [passedState])

    useEffect(() => {
        if (order.length === 0) {
            return;
        }

        const getOrder = async () => {
            const response = await getOrderDetails(order[0]);
            if (!response.ok) {
                setError("Failed to load order.");
                return;
            }

            const orderDetails = await response.json();

            const promises = orderDetails.Profiles.map((profile) => {
                return getProfileDetails(profile.ID);
            });

            Promise.all(promises).then((results) => {
                setProfileDetails(results);
            });
            setOrderDetails(orderDetails);
            setTestDetails(orderDetails.Tests);
            setFacility(orderDetails.Facility);
        };

        getOrder();
    }, [order]);

    useEffect(() => {
        if (step === 1 && orderDetails?.Reflexes) {
            if (orderDetails?.Reflexes.length === 0) {
                setReflexesLoaded(true);
                return;
            }
            const promises = orderDetails?.Reflexes.map((reflex) => {
                return getReflex(reflex.ID);
            });
            Promise.all(promises).then((results) => {
                setReflexDetails(results);
                setReflexesLoaded(true);
            });
        }
    }, [step, orderDetails.Reflexes]);

    const handleChangeStep = (stepFn) => {
        const newStep = stepFn(step);
        if (newStep !== 2) {
            setStep(newStep);
            return
        }

        const createSamples = async () => {
            console.log('create shipment ref', shipmentFormRef);
            const sampleArray = sampleDetails.flatMap((sample) =>
                Array.from({ length: sample.QTY }).map(() => ({
                    OrderID: orderDetails.ID,
                    Barcode: sample.Barcode,
                    DateCollected: new Date(dateCollected),
                    SpecimenTypeID: sample.ID,
                    CollectorID: collector,
                    Fasting: fasting,
                }))
            );

            const s = await addSamples(sampleArray);
            if (!s.ok) {
                throw new Error("Failed to add samples");
            }
            const samples = await s.json();

            if (
                ["createShipment", "receiveShipment"].includes(shipmentOption)
            ) {
                const sp = {
                    ...shipmentFormRef.current.values,
                    Samples: samples.map((sam) => sam.ID),
                };

                const p = await addShipment(sp);
                if (!p.ok) {
                    throw new Error(
                        "Failed to save shipment. Please try again."
                    );
                }

                const shipment = await p.json();
                if (shipmentOption === "receiveShipment") {
                    await receiveShipment(shipment.ID);
                }
            }
            navigate(`/orders/${orderDetails.ID}`);
        };

        createSamples();

    }

    const onlyUnique = (value, index, self) => {
        return self.findIndex((v) => v.ID === value.ID) === index;
    };

    const handleShipmentSubmit = async (values) => {
        console.log('shipment values', values);
    }
    console.log('shipment ref', shipmentFormRef);

    const shipmentForm = (
        <>
            <Formik
                initialValues={shipmentValues}
                validationSchema={shipmentSchema}
                onSubmit={handleShipmentSubmit}
                enableReinitialize
                innerRef={shipmentFormRef}
            >
                {() => (
                    <Form>
                        <ShipmentFormFields filter={"Collected"} />
                    </Form>)
                }
            </Formik>

        </>
    )


    const renderStep = () => {
        if (step === 0) {
            return (
                <Box
                    sx={{
                        height: "500px",
                        width: "100%",
                        mb: 4,
                    }}
                >
                    <OrdersTable
                        setOrder={setOrder}
                        order={order}
                        ordersURL="/orders?filter=Submitted,Sample(s) Collected,Sample(s) Received"
                        title="Orders"
                    />
                </Box>
            );
        } else if (step === 1) {
            if (
                testDetails === undefined ||
                profileDetails === undefined ||
                reflexesLoaded === false
            ) {
                return <></>;
            }
            let t = [...testDetails];
            for (let profile of profileDetails) {
                if (profile?.AllTests === undefined) {
                    continue;
                }
                t.push(...profile.AllTests);
            }

            t = t.filter(onlyUnique);
            return (
                <OrderSamples
                    facility={facility}
                    tests={t}
                    samples={samples}
                    setSamples={setSamples}
                    sampleDetails={sampleDetails}
                    setSampleDetails={setSampleDetails}
                    collector={collector}
                    reflexDetails={reflexDetails}
                    setCollector={setCollector}
                    dateCollected={dateCollected}
                    setDateCollected={setDateCollected}
                    disableScheduleCollection={true}
                    shipmentOption={shipmentOption}
                    setShipmentOption={setShipmentOption}
                    laboratories={laboratories}
                    laboratory={laboratory}
                    setLaboratory={setLaboratory}
                    courier={courier}
                    setCourier={setCourier}
                    startMil={startMil}
                    endMil={endMil}
                    setStartMil={setStartMil}
                    setEndMil={setEndMil}
                    fasting={fasting}
                    setFasting={setFasting}
                    courierName={courierName}
                    setCourierName={setCourierName}
                    dateShipped={dateShipped}
                    setDateShipped={setDateShipped}
                    tracking={tracking}
                    setTracking={setTracking}
                    shipmentForm={shipmentForm}
                />
            );
        }
    };

    const validateCompleted = (step) => {
        if (step === 1) {
            if (order.length === 0) {
                setStep(0);
                return (
                    <Alert severity="error">
                        You must select an order before continuing.
                    </Alert>
                );
            }
        } else if (step === 2) {
            if (!collector) {
                setStep(1);
                return (
                    <ErrorAlert error={"You must select a collector before continuing."} />
                );
            }

            if (isNaN(new Date(dateCollected))) {
                setStep(1);
                return (
                    <Alert severity="error">
                        Please provide a valid collection date.
                    </Alert>
                );

            }

            if (samples.length === 0) {
                return (
                    <Alert severity="error">
                        You must select a sample before continuing.
                    </Alert>
                );
            }

            if (userRoles.includes("Sample Handler") &&
                sampleDetails.some((s) => s.Barcode === "")
            ) {
                return (
                    <Alert severity="error">
                        The sample barcode cannot be empty.
                    </Alert>
                );

            }

        }
    };

    const handleReset = () => {
        setStep(0);
        setOrder(undefined);
        setSamplesCreated(false);
        setCollector(undefined);
    };

    return (
        <Framework>
            <Box sx={{ m: 2 }}>
                {renderStep()}
                <SampleLinearStepper
                    step={step}
                    setStep={handleChangeStep}
                    validateCompleted={validateCompleted}
                    handleResetCallback={handleReset}
                />
            </Box>
        </Framework>
    );
}
