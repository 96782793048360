import Box from "@mui/system/Box";
import PatientsTable from "../tables/PatientsTable";
import { Button, Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Framework from "../components/Framework";
import { useNavigate } from "react-router";
import { getPatients } from "../services/patients";
import { useTabState } from "../components/useTabState";
import TableFrame from "../components/TableFrame";
import { DataTableContainer } from "../components/TableContainer";
import ButtonTray from "../components/ButtonTray";

export default function Patients() {
    const navigate = useNavigate();

    const [value, setValue] = useTabState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <Framework>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Patients</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="All" {...a11yProps(0)} />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel value={value} index={0}>
                <TableFrame>
                    <DataTableContainer>
                        <PatientsTable
                            setPatient={(p) => {
                                navigate("/patients/" + p);
                            }}
                            getPatients={getPatients}
                        />
                    </DataTableContainer>
                    <ButtonTray>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => navigate("/newPatient")}
                        >
                            Create
                        </Button>
                    </ButtonTray>
                </TableFrame>
            </TabPanel>
        </Framework>
    );
}
