import * as Yup from "yup";
import { Form, Formik, FormikProps } from "formik";
import React, { ReactNode, useRef, useState, useMemo, useEffect } from "react";
import FacilityProviderPatient from "../OrderSteps/FacilityProviderPatient";
import { Box, Divider, Stack, Toolbar, Typography } from "@mui/material";
import OrderStepper from "./OrderStepper";
import OrderTestsStep from "./OrderTestsStep";
import OrderPaymentMethodStep from "./OrderPaymentMethodStep";
import OrderSamplesStep from "./OrderSamplesStep";
import { Patient, PaymentMethod, Facility, Reflex, Test, Provider, InsurancePay, Shipment, Order, DiagnosisCode, Sample, Collector } from "../../constants/types";
import { FormikErrors } from "../ErrorAlert";
import { validationSchema as shipmentSchema } from "../../pages/NewShipment";
import { addOrder, addProviderNotes } from "../../services/orders";
import { addSamples } from "../../services/samples";
import { addShipment, receiveShipment } from "../../services/shipments";
import { paymentMethodIndex } from "../../services/util";

const validationSchemas = [
    Yup.object().shape({
        Patient: Yup.object().shape({ ID: Yup.number() }).required("Patient is required"),
        Facility: Yup.object().shape({ ID: Yup.number() }).required("Facility is required"),
        Provider: Yup.object().shape({ ID: Yup.number() }).required("Provider is required"),
    }),
    Yup.object().shape({
        Tests: Yup.array().min(1, "At least one test is required"),
    }),
    Yup.object().shape({
        PaymentMethod: Yup.number().required("Payment method is required"),
        DiagnosisCodes: Yup.array().when("PaymentMethod", {
            is: InsurancePay, then: (schema) => schema.min(1, "At least one diagnosis code is required")
        }),
    }),
    Yup.object().shape({
        Collector: Yup.object().when("SampleCollectionMethod", {
            is: "collectNow", then: (schema) => schema.required("Collector is required"), otherwise: (schema) => schema.nullable(),
        }),
        Samples: Yup.array().of(Yup.object({
            DateCollected: Yup.string().required("Date collected is required"),
        })).when("SampleCollectionMethod", {
            is: "collectNow", then: (schema) => schema.min(1, "At least one sample is required")
        }),
    })
]

const initValues = {
    Facility: null,
    Provider: null,
    Patient: null,
    CollectorID: null,
    Collector: null,
    Tests: [],
    Profiles: [],
    Reflexes: [],
    PaymentMethod: null,
    DiagnosisCodes: [],
    RequestedCollectionDate: null,
    Stat: false,
    Samples: [],
    SampleCollectionMethod: "scheduleCollection",
    ChartNotes: "",
    ChartNotesRequired: false,
    ShipmentOption: "noShipment",

    TestDetails: [],
    ReflexDetails: [],
}

const shipmentValues = {
    "NewOrderShipment": true,
    "Tracking": "",
    "Type": "package", // package or courier
    "Samples": [],
    "DateShipped": new Date(),
    "Courier": "",
    "StartMileage": null,
    "StopMileage": null,
    "DestinationID": null,
}

export type OrderValues = {
    Facility: Facility | null,
    Provider: Provider | null,
    Patient: Patient | null,
    Collector: Collector | null,
    Tests: number[],
    Profiles: number[],
    Reflexes: number[],
    PaymentMethod: PaymentMethod | null,
    DiagnosisCodes: DiagnosisCode[],
    RequestedCollectionDate: string | null,
    Stat: boolean,
    Samples: SampleValues[],
    SampleCollectionMethod: string,
    ChartNotes: string,
    ChartNotesRequired: boolean,
    ShipmentOption: string,

    TestDetails: Test[],
    ReflexDetails: Reflex[],
}


type OrderStep = {
    label: string,
    child: ReactNode,
    additionalRef?: React.RefObject<FormikProps<any>>,
    validationSchema?: Yup.ObjectSchema<any>,
    when: (values: OrderValues) => boolean,
}

export type SampleValues = {
    Barcode: string,
    OrderID: number,
    DateCollected: string,
    SpecimenTypeID: number,
    CollectorID: number,
    Fasting: boolean,
}

const prepareOrder = (values: OrderValues) => {
    return {
        ReferenceID: "",
        Tests: values.Tests,
        Profiles: values.Profiles,
        Reflexes: values.Reflexes,
        ProviderID: values.Provider?.ID,
        FacilityID: values.Facility?.ID,
        DiagnosisCodes: values.DiagnosisCodes,
        // @ts-ignore
        PaymentMethod: values.PaymentMethod,
        Stat: values.Stat,
        PatientID: values.Patient?.ID,
        ProviderNotesRequired: values.ChartNotesRequired,
        ...(values.SampleCollectionMethod === "scheduleCollection" && {
            RequestedCollectionDate: new Date(
                // @ts-ignore
                values.RequestedCollectionDate
            ).toISOString(),
        }),
    };

}


function OrderForm({ setOrderId, reset }: { setOrderId: (orderId: number) => void, reset: boolean }) {
    const [step, setStep] = useState(0)
    const formikRef = useRef<FormikProps<OrderValues>>(null)
    const shipmentFormRef = useRef<FormikProps<Shipment>>(null)
    const shipmentId = useRef<number | null>(null)
    const [error, setError] = useState<null | string>(null)

    useEffect(() => {
        handleReset()
    }, [reset])

    const handleShipmentSubmit = async (values: any) => {
        console.log('shipment values', values)
        try {
            const response = await addShipment(shipmentSchema.cast(values))
            const shipment = await response.json()
            shipmentId.current = shipment?.ID
            return shipment?.ID
        } catch (e) {
            console.log(e)
            setError("Failed to save shipment.")
        }
    }

    const steps: OrderStep[] = useMemo(() => [{
        label: "Select patient",
        child: <FacilityProviderPatient />,
        when: (_: OrderValues) => false,
    }, {
        label: "Select tests",
        child: <OrderTestsStep />,
        when: (_: OrderValues) => false,
    }, {
        label: "Select payment method",
        child: <OrderPaymentMethodStep />,
        when: (_: OrderValues) => false,
    }, {
        label: "Sample collection",
        child: <OrderSamplesStep
            shipmentFormRef={shipmentFormRef}
            handleShipmentSubmit={handleShipmentSubmit}
            shipmentValues={shipmentValues}
        />,
        validationSchema: shipmentSchema,
        additionalRef: shipmentFormRef,
        when: (values: OrderValues) => ["receiveShipment", "createShipment"].includes(values.ShipmentOption),
    }], [])

    const handleSubmit = async (values: any) => {
        const order = prepareOrder(values)
        let orderData: Order;
        try {
            const response = await addOrder(order);
            if (!response.ok) {
                setError("Failed to save order. Please try again.");
                return
            }
            orderData = await response.json();

        } catch (error) {
            console.log(error)
            return
        }

        if (values?.ChartNotes !== "") {
            const n = {
                OrderID: orderData.ID,
                ProviderID: values.Provider?.ID,
                Note: values.ChartNotes,
            }

            try {
                addProviderNotes(n)
            } catch (error) {
                console.log(error)
            }
        }

        if (values?.SampleCollectionMethod === "collectNow") {
            // collector is set via the order samples step
            // we reset it here to make sure
            const samples = values.Samples.flatMap((s: SampleValues) => ({
                ...s,
                CollectorID: values.Collector?.ID,
                OrderID: orderData?.ID,
            }))

            const response = await addSamples(samples);
            if (!response.ok) {
                setError("Order created. Failed to add samples");
                return
            }

            const sampleData = await response.json();
            if (
                ["createShipment", "receiveShipment"].includes(
                    values?.ShipmentOption
                )
            ) {

                if (shipmentFormRef.current) {
                    const sampleIds = sampleData.map((sam: Sample) => sam.ID)
                    samples.current = sampleIds
                    let shipment = shipmentFormRef.current.values
                    shipment.Samples = sampleIds
                    await handleShipmentSubmit(shipment);
                    if (shipmentId.current && values.ShipmentOption === "receiveShipment") {
                        await receiveShipment(shipmentId.current)
                    }
                }
            }
        }

        formikRef.current?.resetForm()
        shipmentFormRef.current?.resetForm()
        setOrderId(orderData.ID)
    }

    const handleBack = () => {
        setStep((prevStep) => prevStep - 1)
    }

    const handleNext = () => {
        console.log('shipment values', shipmentFormRef.current?.values)
        console.log('shipment errors', shipmentFormRef.current?.errors)
        console.log('values', formikRef.current?.values)
        console.log('errors', formikRef.current?.errors)
        if (!formikRef.current || !formikRef.current.values) {
            return
        }

        if (!formikRef.current?.isValid || (
            steps[step].when(formikRef.current.values) &&
            !steps[step].additionalRef?.current?.isValid)) {

            const fields = validationSchemas[step].fields
            for (const field in fields) {
                console.log('setting touched', field)
                formikRef.current?.setFieldTouched(field)
            }

            // set additional forms touched field
            if (steps[step].when(formikRef.current.values)) {
                const fields = steps[step].validationSchema?.fields
                for (const field in fields) {
                    steps[step].additionalRef?.current?.setFieldTouched(field)
                }
            }
            return
        }

        if (step + 1 === steps.length && formikRef.current?.isValid) {
            formikRef.current?.submitForm()
            return
        }

        if (formikRef.current?.isValid) {
            setStep((prevStep) => prevStep + 1)
        } else {
            // set touched on each field so the errors appear
            const fields = validationSchemas[step].fields
            for (const field in fields) {
                console.log('setting touched', field)
                formikRef.current?.setFieldTouched(field)
            }
        }
    }

    const handleReset = () => {
        formikRef.current?.resetForm()
        shipmentFormRef.current?.resetForm()
        setStep(0)
    }

    const getPaymentMethod = (values: OrderValues) => {
        if (!values?.PaymentMethod === null) {
            return ""
        }
        // @ts-ignore
        return paymentMethodIndex[values.PaymentMethod]
    }

    return (
        <Box>
            <Formik
                initialValues={initValues}
                validationSchema={validationSchemas[step]}
                onSubmit={handleSubmit}
                innerRef={formikRef}
            >
                {({ errors, touched, values }) => (
                    <>

                        <Stack spacing={2} direction="column">
                            {step > 0 &&
                                <Toolbar sx={{ borderRadius: "15px", width: "100%", height: "64px", boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)" }} >
                                    <Stack direction="row" divider={<Divider orientation="vertical" />} spacing={2}>
                                        <Typography variant="body2"><b>Facility: </b>{values?.Facility?.Name}</Typography>
                                        <Typography variant="body2"><b>Patient: </b>{values?.Patient?.LastName}, {values?.Patient?.FirstName}</Typography>
                                        <Typography variant="body2"><b>Tests Selected: </b>{values?.Tests.length}</Typography>
                                        <Typography variant="body2"><b>Profiles Selected: </b>{values?.Profiles.length}</Typography>
                                        <Typography variant="body2"><b>Payment Method: </b>{getPaymentMethod(values)}</Typography>
                                    </Stack>
                                </Toolbar>
                            }
                            <Form>
                                {steps[step].child}
                            </Form>
                            <FormikErrors errors={errors} touched={touched} />
                            <OrderStepper
                                formikRef={formikRef}
                                activeStep={step}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                handleReset={handleReset}
                                steps={steps.map((s) => s.label)}
                            />
                        </Stack>
                    </>
                )}

            </Formik>
        </Box>
    )
}

export default OrderForm;
