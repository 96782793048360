import React, { useEffect, useState, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Material UI
import {
    Box,
    Button,
    Divider,
    Grid,
    Alert,
    Paper,
    Typography,
} from "@mui/material";

import {
    PictureAsPdf,
    ModeEdit,
    Pageview,
    Cancel,
    HealthAndSafety,
    WarningAmber,
    Add,
    PostAdd,
} from "@mui/icons-material";

import { GridToolbar } from "@mui/x-data-grid-pro";
import { Stack } from "@mui/system";

// Services
import {
    updateOrderView,
    getOrderDetails,
    getOrderResults,
} from "../services/orders";
import { getFacility } from "../services/facilities";

import { SessionContext } from "../hooks/getContext";

// Custom
import Title from "./Title";
import Framework from "./Framework";
import {
    GetResultStatusChip,
    GetOrderSTATChip,
} from "./StatusChips";

// Dialogs
import { BarcodeDialog } from "./SampleBarcodes";
import AddAttachment from "./NewAttachment";

// Tables
import NotesTable from "./NotesTable";
import AttachmentsTable from "./AttachmentsTable";
import PatientInsurancesTable from "./InsurancesTable";
import DiagnosisCodeTable from "./DiagnosisCodesTable";

// Order Display
import { StyledOrdersResultsTable } from "./StyledOrdersResultsTable";
import OrderMetadata from "./OrderDisplay/OrderMetadata";
import Profiles from "./OrderDisplay/OrderProfiles";
import Tests from "./OrderDisplay/OrderTests";
import AddOns from "./OrderDisplay/AddOns";
import Reflexes from "./OrderDisplay/OrderReflexes";
import Samples from "./OrderDisplay/OrderSamples";
import FacilityMeta from "./OrderDisplay/OrderFacility";
import Patient from "./OrderDisplay/OrderPatient";
import ChangePaymentMethodDialog from "./ChangePaymentMethodDialog";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";
import AOEs from "./OrderDisplay/AOEs";
import ChangeDiagnosisCodesDialog from "./ChangeDiagnosisCodesDialog";
import ConfirmDialog from "./ConfirmDialog";
import ButtonList from "./ButtonList";
import {
    AddOnDialog,
    AddNoteDialog,
    AddTestDialog,
    AddReflexDialog,
    AddProfileDialog,
} from "./OrderDialogs";
import { OrderFinalStatuses } from "../constants/constants";

const StyledPaper = (props) => (
    <Paper
        elevation={5}
        sx={{
            p: { xs: 1, md: 2 },
            // m: { xs: 1, sm: 1, md: 2 },
            height: "100%",
        }}
    >
        {props.children}
    </Paper>
);

function ResultsTable({ results }) {
    const getAbnormal = (value) => {
        if (value.value) {
            return <WarningAmber color="warning" />;
        }
        return <WarningAmber color="disabled" />;
    };
    const getCritical = (value) => {
        if (value.value) {
            return <HealthAndSafety color="error" />;
        }
        return <HealthAndSafety color="disabled" />;
    };
    const getTNP = (value) => {
        if (value.value) {
            return <Cancel color="error" />;
        }
        return <></>;
    };

    const columns = useMemo(() => [
        {
            field: "Barcode",
            headerName: "Sample",
            width: 125,
            valueGetter: (row) => row.row.Sample.Barcode,
        },
        {
            field: "Code",
            headerName: "Code",
            width: 75,
            valueGetter: (row) => row.row.Test.Code,
        },
        {
            field: "Test",
            headerName: "Test",
            width: 200,
            valueGetter: (row) => row.row.Test.Name,
        },
        {
            field: "Status",
            headerName: "Status",
            width: 220,

            renderCell: (params) => (
                <GetResultStatusChip status={params.row?.Status} />
            ),
        },
        {
            field: "FinalValue",
            headerName: "Result",
            width: 200,
        },
        {
            field: "Units",
            headerName: "Units",
            width: 80,
            valueGetter: (row) => row.row.Method.Units,
        },
        {
            field: "Abnormal",
            headerName: "Abnormal",
            width: 100,
            renderCell: getAbnormal,
        },
        {
            field: "Critical",
            headerName: "Critical",
            width: 100,
            renderCell: getCritical,
        },
        {
            field: "TNP",
            headerName: "TNP",
            width: 100,
            renderCell: getTNP,
        },
        {
            field: "ValidatedBy",
            headerName: "ValidatedBy",
            width: 180,
            valueGetter: (row) => row.row.ValidatedBy.Email,
        },
    ], []);

    return (
        <StyledOrdersResultsTable
            getRowId={(row) => row.ID}
            defaultGroupingExpansionDepth={2}
            groupingColDef={{ headerName: "ID", width: 125, valueGetter: (params) => params.id, sortable: true, filterable: true }}
            treeData
            getTreeDataPath={(row) => row.Path}
            getRowClassName={(params) => {
                if (params.row.Critical) {
                    return "critical";
                } else if (params.row.Abnormal) {
                    return "abnormal";
                } else if (params.row.TNP) {
                    return "tnp";
                }
                return "";
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: "DateReceived", sort: "desc" }],
                },
            }}
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                {
                    display: "none",
                },
                height: "760px",
            }}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            autoHeight
            pageSize={100}
            rows={results}
            columns={columns}
        />
    );
};


export default function OrderDisplay({ withNav, ID }) {
    let { orderID } = useParams();
    const { user } = useContext(SessionContext);
    const [orderDetails, setOrderDetails] = useState();
    const [printLabels, setPrintLabels] = useState(false);
    const [error, setError] = useState(null);
    const [del, setDelete] = useState(false);
    const [facility, setFacility] = useState({});
    const navigate = useNavigate();
    const [results, setResults] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [aoes, setAOEs] = useState([]);
    const [dialogStates, setDialogStates] = useState({
        AddOn: false,
        Test: false,
        Profile: false,
        Reflex: false,
        Note: false,
        Attachment: false,
        Payment: false,
        Diagnosis: false,
    });
    const [providerNotes, setProviderNotes] = useState([]);
    const [patientAttachments, setPatientAttachments] = useState([]);
    const [sentToReportService, setSentToReportService] = useState(false);
    const [completeOrderError, setCompleteOrderError] = useState(null);
    const session = useContext(SessionContext);
    const isEditable = OrderFinalStatuses.includes(orderDetails?.Status)

    if (ID) {
        orderID = ID;
    }

    const groupResults = (results) => {
        return results.map((p) => {
            if (p.PanelID !== null) {
                p.Path = [p.PanelID, p.ID]
            } else {
                p.Path = [p.ID]
            }
            return p
        })
    }


    useEffect(() => {
        console.log(session);
        const getDetails = async () => {
            try {
                const response = await getOrderDetails(orderID);
                if (!response.ok) {
                    throw new Error("Failed to load order.");
                }
                const order = await response.json();
                setOrderDetails(order);

                const resp = await getFacility(order.Facility.ID);
                if (!resp.ok) {
                    throw new Error("Failed to load order.");
                }
                const fac = await resp.json();

                setFacility(fac);

                updateOrderView(orderID, true);
            } catch (e) {
                setError(e.message);
            }
        };

        const getAOEs = async () => {
            try {
                const aoeResponse = await apiFetch(`/orders/${orderID}/aoes`);
                const dqResponse = await apiFetch(`/orders/${orderID}/diagnosisQuestions`);
                const qs = dqResponse.map((q) => (
                    { Answer: q?.Answer, AOE: { Question: q?.DiagnosisQuestion?.Question } }
                ))
                setAOEs([...aoeResponse, ...qs])
            } catch (e) {
                setError("Failed to load AOEs")
            }

        }

        const getProviderNotes = async () => {
            try {
                const noteResponse = await apiFetch(`/providers/notes?orderId=${orderID}`);
                setProviderNotes(noteResponse)
            } catch (e) {
                setError("Failed to load provider notes")
            }
        }

        getDetails();
        getAOEs();
        getProviderNotes();
    }, [orderID, refresh]);

    useEffect(() => {
        const getResults = async () => {
            try {
                const response = await getOrderResults(orderID);
                if (!response.ok) {
                    throw new Error("Failed to load results.");
                }
                const results = await response.json();

                // filter out updated results so client only sees validated results with values
                // filters out dependent/internal tests
                let r = [];
                for (let result of results) {
                    if (result.Status !== "Validated") {
                        result.Value = "";
                        result.FinalValue = "";
                        result.Abnormal = false;
                        result.Critical = false;
                    }

                    if (result.Status === "Updated") {
                        result.Status = "Pending";
                    }

                    if (result.IsDependency || !result.IsVisible) {
                        continue;
                    }

                    r.push(result);
                }

                r = groupResults(r)

                setResults(r);
            } catch (e) {
                setError(e.message);
            }
        };

        const getPatientAttachments = async () => {
            if (!orderDetails?.Patient?.ID) {
                return
            }
            const response = await apiFetch(`/patients/${orderDetails?.Patient?.ID}/attachments?patientOnly=true`)
            setPatientAttachments(response)
        }


        getResults();
        getPatientAttachments();
    }, [orderDetails, orderID, refresh]);

    const handleResendReportService = () => {
        const resend = async () => {
            try {
                await apiFetch(`/orders/${orderID}/integrations`, "POST", {})
                setSentToReportService(true)
            } catch (e) {
                console.log(e)
            }
        }

        resend()
    }

    const handleCompleteOrder = () => {
        const complete = async () => {
            try {
                await apiFetch(`/orders/${orderID}/status`, "POST", {})
                setRefresh(!refresh)
            } catch (e) {
                setCompleteOrderError(e.message)
            }
        }
        complete()
    }



    const delOrder = async () => {
        try {
            await apiFetch(`/orders/${orderDetails.ID}`, 'DELETE')
            navigate(`/orders`)
        } catch (e) {
            setError("Failed to delete order")
        } finally {
            setDelete(false)
        }
    }

    const changeDialogs = (d, b) => {
        setDialogStates({ ...dialogStates, [d]: b })
    }

    console.log('orderDetails', orderDetails)

    const Order = () => {
        return (
            <>
                <AddAttachment
                    open={dialogStates.Attachment}
                    setOpen={(o) => changeDialogs("Attachment", o)}
                    orderID={orderID}
                    callback={() => setRefresh(!refresh)}
                />
                <BarcodeDialog
                    orderDetails={orderDetails}
                    open={printLabels}
                    setOpen={setPrintLabels}
                />
                <ConfirmDialog
                    title="Are you sure?"
                    message="Are you sure you want to delete this order?"
                    confirm={delOrder}
                    open={del}
                    cancel={() => setDelete(false)}

                />
                <AddNoteDialog
                    error={error}
                    open={dialogStates.Note}
                    setOpen={(o) => changeDialogs("Note", o)}
                    orderId={orderID}
                    setRefresh={setRefresh}
                />
                <AddProfileDialog
                    orderId={orderID}
                    facilityId={orderDetails?.Facility?.ID}
                    setRefresh={setRefresh}
                    open={dialogStates.Profile}
                    setOpen={(o) => changeDialogs("Profile", o)}
                />
                <AddReflexDialog
                    orderId={orderID}
                    setRefresh={setRefresh}
                    open={dialogStates.Reflex}
                    setOpen={(o) => changeDialogs("Reflex", o)}
                />
                <AddTestDialog
                    orderId={orderID}
                    setRefresh={setRefresh}
                    open={dialogStates.Test}
                    setOpen={(o) => changeDialogs("Test", o)}
                />
                <AddOnDialog
                    orderId={orderID}
                    samples={orderDetails?.Samples}
                    setRefresh={setRefresh}
                    open={dialogStates.AddOn}
                    setOpen={(o) => changeDialogs("AddOn", o)}
                />
                <ChangePaymentMethodDialog
                    open={dialogStates.Payment}
                    setOpen={(o) => changeDialogs("Payment", o)}
                    orderId={orderID}
                    callback={() => setRefresh(!refresh)}
                />
                <ChangeDiagnosisCodesDialog
                    open={dialogStates.Diagnosis}
                    setOpen={(o) => changeDialogs("Diagnosis", o)}
                    orderId={orderID}
                    paymentMethod={orderDetails?.PaymentMethod}
                    callback={() => setRefresh(!refresh)}

                />
                <Box
                    id="order-display-box"
                    sx={{ p: { sm: 1, md: 2 } }}
                    width="100%"
                >
                    <Grid container spacing={{ xs: 1, md: 2, lg: 2 }}>
                        <Grid item md={4} xs={12}>
                            <StyledPaper
                                sx={{
                                    background: "#eceff1",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Title>Order</Title>
                                    <Stack direction="row" spacing={1}>
                                        {user.Roles && user.Roles.includes("Admin") &&
                                            <Button variant="outlined" size="small" color="warning" onClick={handleCompleteOrder}>Force Complete</Button>
                                        }
                                        {orderDetails?.Stat ? (
                                            <GetOrderSTATChip size="medium" />
                                        ) : null}
                                    </Stack>
                                </Stack>
                                <ErrorAlert error={completeOrderError} />
                                <OrderMetadata orderDetails={orderDetails} setPaymentMethod={(o) => changeDialogs("Payment", o)} refresh={() => setRefresh(!refresh)} />
                            </StyledPaper>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <StyledPaper
                                sx={{
                                    background: "#eceff1",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Title>Patient</Title>
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        divider={
                                            <Divider orientation="vertical" />
                                        }
                                    >
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    "/patients/" +
                                                    orderDetails.Patient.ID
                                                )
                                            }
                                            startIcon={<Pageview />}
                                        >
                                            View
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    "/patients/" +
                                                    orderDetails.Patient
                                                        .ID +
                                                    "/edit"
                                                )
                                            }
                                            startIcon={<ModeEdit />}
                                        >
                                            Edit
                                        </Button>
                                    </Stack>
                                </Stack>
                                <Patient orderDetails={orderDetails} />
                            </StyledPaper>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <StyledPaper
                                sx={{
                                    background: "#eceff1",
                                }}
                            >
                                <Title>Facility</Title>
                                <FacilityMeta orderDetails={orderDetails} facility={facility} />
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={3}>
                            <StyledPaper>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Title>Diagnosis Codes</Title>
                                    <Button
                                        onClick={() =>
                                            changeDialogs("Diagnosis", true)
                                        }
                                        startIcon={<ModeEdit />}
                                    >
                                        Edit
                                    </Button>
                                </Stack>
                                <DiagnosisCodeTable
                                    diagnosisCodes={
                                        orderDetails.DiagnosisCodes
                                    }
                                />
                            </StyledPaper>
                        </Grid>
                        {orderDetails.PaymentMethod === 2 ? (
                            <>
                                <Grid item xs={12} md={12} lg={9}>
                                    <StyledPaper>
                                        <Title>Insurance Details</Title>
                                        <PatientInsurancesTable
                                            insurances={
                                                orderDetails.Patient
                                                    .PatientInsurances
                                            }
                                        />
                                    </StyledPaper>
                                </Grid>
                            </>
                        ) : (
                            <></>
                        )}
                        {(orderDetails.Attachments?.length > 0 || patientAttachments.length > 0) && (
                            <Grid item xs={12} md={6}>
                                <StyledPaper>
                                    <Stack direction="row" justifyContent={"space-between"}>
                                        <Title>Attachments</Title>
                                        <Button
                                            variant="outlined"
                                            component="label"
                                            onClick={() => { changeDialogs("Attachment", true) }}
                                            startIcon={<PostAdd />}>
                                            Add Attachment
                                        </Button>
                                    </Stack>
                                    <AttachmentsTable
                                        orderDetails={orderDetails}
                                        patientAttachments={patientAttachments}
                                        callback={() => setRefresh(!refresh)}
                                    />
                                </StyledPaper>
                            </Grid>
                        )}
                        {aoes?.length > 0 && (
                            <Grid item xs={12} md={4} lg={4}>
                                <Box>
                                    <StyledPaper>
                                        <Title>AOEs</Title>
                                        <AOEs aoes={aoes} />
                                    </StyledPaper>
                                </Box>
                            </Grid>
                        )}

                        {orderDetails.Tests?.length > 0 && (
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <Box>
                                    <StyledPaper>
                                        <Stack direction="row" justifyContent={"space-between"}>
                                            <Title>Tests</Title>
                                            <Button variant="outlined" disabled={isEditable} startIcon={<Add />} onClick={() => changeDialogs("Test", true)}>Add Tests</Button>
                                        </Stack>
                                        <Tests orderDetails={orderDetails} callback={() => setRefresh(!refresh)} />
                                    </StyledPaper>
                                </Box>
                            </Grid>)}
                        {orderDetails.Profiles?.length > 0 && (
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <StyledPaper>
                                    <Title>Profiles</Title>
                                    <Profiles orderDetails={orderDetails} callback={() => setRefresh(!refresh)} />
                                </StyledPaper>
                            </Grid>
                        )}
                        {orderDetails.AddOns?.length > 0 && (
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <StyledPaper>
                                    <Title>Add Ons</Title>
                                    <AddOns orderDetails={orderDetails} />
                                </StyledPaper>
                            </Grid>
                        )}
                        {orderDetails.Reflexes?.length > 0 && (
                            <Grid item sm={12}>
                                <StyledPaper>
                                    <Title>Reflexes</Title>
                                    <Reflexes orderDetails={orderDetails} callback={() => setRefresh(!refresh)} />
                                </StyledPaper>
                            </Grid>
                        )}
                        {providerNotes?.length > 0 && (
                            <Grid item xs={12} md={8}>
                                <StyledPaper>
                                    <Title>Provider/Chart Notes</Title>
                                    <NotesTable notes={providerNotes} />
                                </StyledPaper>
                            </Grid>
                        )}
                        {orderDetails.Notes?.length > 0 && (
                            <Grid item xs={12} md={8}>
                                <StyledPaper>
                                    <Title>Notes</Title>
                                    <NotesTable notes={orderDetails?.Notes} />
                                </StyledPaper>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <StyledPaper>
                                <Stack direction="row" justifyContent="space-between">
                                    <Title>Samples</Title>
                                    <Button variant="outlined" disabled={isEditable} startIcon={<Add />} onClick={() => navigate('/newSample', { state: { orderId: orderDetails?.ID } })}>Add Sample</Button>
                                </Stack>
                                {orderDetails?.Samples?.length > 0 ? (
                                    <Samples
                                        orderDetails={orderDetails}
                                        isEditable={!isEditable}
                                        callback={() => setRefresh(!refresh)}
                                    />
                                ) : (
                                    <Typography>Pending collection</Typography>
                                )}
                            </StyledPaper>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledPaper>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{ mb: 1 }}
                                >
                                    <Title>Results</Title>
                                    <Stack direction="row" spacing={1}>
                                        {user.Roles && user.Roles.includes("Admin") &&
                                            <Button
                                                onClick={handleResendReportService}
                                                disabled={sentToReportService}
                                                variant="outlined"
                                            >
                                                Resend to Report Service
                                            </Button>}
                                        <Button
                                            startIcon={
                                                <PictureAsPdf color="error" />
                                            }
                                            href={`/orders/${orderID}/resultReport`}
                                            target="_blank"
                                            variant="outlined"
                                        >
                                            View Report
                                        </Button>
                                    </Stack>
                                </Stack>
                                <ResultsTable results={results} />
                            </StyledPaper>
                        </Grid>
                    </Grid>

                    <Divider sx={{ m: 2 }}></Divider>

                    {error !== null ? (
                        <Alert severity="error">{error}</Alert>
                    ) : null}

                    <Stack
                        padding={2}
                        direction={{ xs: "column-reverse", md: "row" }}
                        spacing={{ xs: 1, md: 2 }}
                        justifyContent="space-between"
                    >
                        <Button variant="outlined" onClick={() => navigate(-1)}>
                            Back
                        </Button>
                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={{ xs: 1, md: 2 }}
                            divider={<Divider orientation="vertical" />}
                        >
                            {user.Roles && user.Roles.includes("Admin") &&
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{ color: "white" }}
                                    onClick={() => setDelete(true)}
                                >
                                    Delete
                                </Button>}
                            <ButtonList options={[
                                { Name: "Add Attachment", Action: () => changeDialogs("Attachment", true) },
                                { Name: "Request Add On", Action: () => changeDialogs("AddOn", true) },
                                { Name: "Add Test", Action: () => changeDialogs("Test", true), Disabled: isEditable },
                                { Name: "Add Profile", Action: () => changeDialogs("Profile", true), Disabled: isEditable },
                                { Name: "Add Reflex", Action: () => changeDialogs("Reflex", true), Disabled: isEditable },
                                { Name: "Add Note", Action: () => changeDialogs("Note", true) },
                            ]} />
                            <Button
                                variant="contained"
                                disabled={orderDetails.Samples.length === 0}
                                onClick={() => setPrintLabels(true)}
                            >
                                Print Labels
                            </Button>
                            <Button
                                href={`/orders/${orderID}/printOrder`}
                                variant="contained">
                                Print Order
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </>
        );
    };

    if (orderDetails === undefined) {
        return <></>;
    } else if (withNav) {
        return <Framework><Order /></Framework>;
    } else {
        return <Order />;
    }
}
