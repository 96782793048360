import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { FormatDate, FormatDateUTC } from "../services/util";
import { GetOrderStatusChips, GetOrderApprovalChip } from "./StatusChips";
import CustomTabs from "./CustomTabs";
import TabPanel from "../components/TabPanel";
import { MiniTableContainer } from "./TableContainer";
import { apiFetch } from "../services/fetch";
import ServerSideDataTable from "./ServerSideDataTable";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const queries = {
    0: "/orders?requiresApproval=true&reviewed=false",
    1: "/orders?requiresApproval=true&reviewed=true",
}

function PatientOrders() {
    const [tab, setTab] = useState(0)
    const [data, setData] = useState([])
    const [queryOptions, setQueryOptions] = useState({
        pagination: { pageSize: 500, page: 0 },
        items: [],
        logicOperator: 'and',
        quickFilterExcludeHiddenColumns: false,
        quickFilterLogicOperator: 'and',
        quickFilterValues: [],
        sorts: [{ field: "ID", sort: "desc" }]
    })
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)


    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                const response = await apiFetch(queries[tab], 'POST', queryOptions)
                setData(response)
            } catch (e) {
                setError("Failed to load orders.")
            } finally {
                setLoading(false)
            }

        }

        init()

    }, [tab, queryOptions])



    const renderDetailsButton = (params) => {
        return (
            <strong>
                <Button
                    component="a"
                    variant="contained"
                    color="primary"
                    size="small"
                    href={`/orders/${params.id}`}
                    target="_blank"
                >
                    Review
                </Button>
            </strong>
        );
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 60, type: 'number' },
        {
            field: "Approved",
            headerName: "Review Status",
            width: 200,
            type: 'boolean',
            filterable: false,
            sortable: false,
            renderCell: (row) => <GetOrderApprovalChip approved={row.row?.Approved} />,
        },
        {
            field: "Facility",
            headerName: "Facility",
            width: 200,
            valueGetter: (row) => row.row.Facility.Name,
        },
        {
            field: "Status",
            headerName: "Status",
            width: 230,
            renderCell: (row) => <GetOrderStatusChips order={row.row} />,
        },
        {
            field: "Patient",
            headerName: "Patient",
            width: 150,
            valueGetter: (row) => {
                return (
                    row.row.Patient.LastName + ", " + row.row.Patient.FirstName
                );
            },
        },
        {
            field: "PatientDOB",
            headerName: "Patient DOB",
            width: 120,
            type: "date",
            valueGetter: (params) => params?.row?.Patient?.DOB && dayjs.utc(params?.row.Patient?.DOB).toDate(),
            valueFormatter: ({ value }) => { return value && FormatDateUTC(value) },
        },
        {
            field: "CreatedAt",
            headerName: "Date Submitted",
            width: 120,
            type: "date",
            valueGetter: (row) => dayjs.utc(row.row.CreatedAt).toDate(),
            valueFormatter: ({ value }) => { return value && FormatDateUTC(value) },
        },
        {
            field: "Action",
            headerName: "Action",
            width: 100,
            filterable: false,
            sortable: false,
            renderCell: (row) => renderDetailsButton(row),
        },
    ];

    return (
        <>
            <CustomTabs tab={tab} setTab={setTab} tabs={["Pending", "Completed"]} />
            <TabPanel value={tab} index={0}>
                <MiniTableContainer>
                    <ServerSideDataTable columns={columns} data={data} loading={loading} error={error}
                        queryOptions={queryOptions} setQueryOptions={setQueryOptions} />
                </MiniTableContainer>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <MiniTableContainer>
                    <ServerSideDataTable columns={columns} data={data} loading={loading} error={error}
                        queryOptions={queryOptions} setQueryOptions={setQueryOptions} />
                </MiniTableContainer>
            </TabPanel>
        </>
    )
}

export default PatientOrders;
