import { Alert, Stack } from "@mui/material";
import { apiFetch } from "./fetch";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const downloadAttachment = async (uuid, filename, setError) => {
    try {
        let uri = `/attachments/${uuid}`

        const data = await apiFetch(uri)
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
        // It's necessary to revoke the object URL to avoid memory leaks
        window.URL.revokeObjectURL(url);
        // setRefresh(!refresh);
    } catch (e) {
        setError(e.message);
    }
};


// temp until values fixed
export const placeOfService = [
    { Code: "01", Name: "Pharmacy" },
    { Code: "02", Name: "Telehealth Provided Other than in Patient’s Home" },
    { Code: "03", Name: "School" },
    { Code: "04", Name: "Homeless Shelter" },
    { Code: "05", Name: "Indian Health Service Free-standing Facility" },
    { Code: "06", Name: "Indian Health Service Provider-based Facility" },
    { Code: "07", Name: "Tribal 638 Free-standing Facility" },
    { Code: "08", Name: "Tribal 638 Provider-based Facility" },
    { Code: "09", Name: "Prison/Correctional Facility" },
    { Code: "10", Name: "Telehealth Provided in Patient’s Home" },
    { Code: "11", Name: "Office" },
    { Code: "12", Name: "Home" },
    { Code: "13", Name: "Assisted Living Facility" },
    { Code: "14", Name: "Group Home" },
    { Code: "15", Name: "Mobile Unit" },
    { Code: "16", Name: "Temporary Lodging" },
    { Code: "17", Name: "Walk-in Retail Health Clinic" },
    { Code: "18", Name: "Place of Employment" },
    { Code: "19", Name: "Off Campus-Outpatient Hospital" },
    { Code: "20", Name: "Urgent Care Facility" },
    { Code: "21", Name: "Inpatient Hospital" },
    { Code: "22", Name: "On Campus-Outpatient Hospital" },
    { Code: "23", Name: "Emergency Room – Hospital" },
    { Code: "24", Name: "Ambulatory Surgical Center" },
    { Code: "25", Name: "Birthing Center" },
    { Code: "26", Name: "Military Treatment Facility" },
    { Code: "27", Name: "Outreach Site/ Street" },
    { Code: "31", Name: "Skilled Nursing Facility" },
    { Code: "32", Name: "Nursing Facility" },
    { Code: "33", Name: "Custodial Care Facility" },
    { Code: "34", Name: "Hospice" },
    { Code: "41", Name: "Ambulance - Land" },
    { Code: "42", Name: "Ambulance – Air or Water" },
    { Code: "49", Name: "Independent Clinic" },
    { Code: "50", Name: "Federally Qualified Health Center" },
    { Code: "51", Name: "Inpatient Psychiatric Facility" },
    { Code: "52", Name: "Psychiatric Facility-Partial Hospitalization" },
    { Code: "53", Name: "Community Mental Health Center" },
    {
        Code: "54",
        Name: "Intermediate Care Facility/Individuals with Intellectual Disabilities",
    },
    { Code: "55", Name: "Residential Substance Abuse Treatment Facility" },
    { Code: "56", Name: "Psychiatric Residential Treatment Center" },
    { Code: "57", Name: "Non-residential Substance Abuse Treatment Facility" },
    { Code: "58", Name: "Non-residential Opioid Treatment Facility" },
    { Code: "59", Name: "Unassigned" },
    { Code: "60", Name: "Mass Immunization Center" },
    { Code: "61", Name: "Comprehensive Inpatient Rehabilitation Facility" },
    { Code: "62", Name: "Comprehensive Outpatient Rehabilitation Facility" },
    { Code: "65", Name: "End-Stage Renal Disease Treatment Facility" },
    { Code: "71", Name: "Public Health Clinic" },
    { Code: "72", Name: "Rural Health Clinic" },
    { Code: "81", Name: "Independent Laboratory" },
    { Code: "99", Name: "Other Place of Service" },
];

export const eligibilityStatus = {
    "Not Checked": 0,
    "Check Failed": 1,
    Active: 2,
    Inactive: 3,
};

export const eligibilityStatusIndex = {
    0: "Not Checked",
    1: "Check Failed",
    2: "Active",
    3: "Inactive",
};

export const notificationEventIndex = {
    0: "Completed Order",
    1: "New Critical",
    2: "New Reflex",
    3: "New Patient Order",
    4: "Patient Order Approved",
    5: "Patient Order Rejected",
};

export const reflexTypes = {
    Test2Test: 0,
    Test2Profile: 1,
    Profile2Profile: 2,
    Profile2Test: 3,
};

export const reflexTypesIndex = {
    0: "Test2Test",
    1: "Test2Profile",
    2: "Profile2Profile",
    3: "Profile2Test",
};

export const formatFee = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
});

export const financialClasses = {
    "Client Bill": 0,
    "Self Pay": 1,
    Commercial: 2,
    Medicare: 3,
    Medicaid: 4,
};

export const financialClassIndex = {
    0: "Client Bill",
    1: "Self Pay",
    2: "Commercial",
    3: "Medicare",
    4: "Medicaid",
};

export const paymentMethods = {
    "Client Bill": 0,
    "Self Pay": 1,
    "Insurance": 2,
    "No Bill": 3,
};

export const paymentMethodIndex = {
    0: "Client Bill",
    1: "Self Pay",
    2: "Insurance",
    3: "No Bill",
};

export const stateAbbreviations = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
];

export const stateNames = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
];

export const Timezones = [
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
]

export function formatAddress(address1, address2, city, state, zipcode) {
    let addressString = "";

    if (address1) {
        addressString += address1;
    }

    if (address2) {
        if (addressString) {
            addressString += ", " + address2;
        } else {
            addressString += address2;
        }
    }

    if (city) {
        if (addressString) {
            addressString += ", " + city;
        } else {
            addressString += city;
        }
    }

    if (state) {
        if (addressString) {
            addressString += ", " + state;
        } else {
            addressString += state;
        }
    }

    if (zipcode) {
        if (addressString) {
            addressString += " " + zipcode;
        } else {
            addressString += zipcode;
        }
    }

    return addressString;
}

export function HoursToHumanReadable(hours) {
    if (hours < 73) {
        return `${hours} (hr)`;
    } else if (hours < 24 * 7) {
        let days = Math.floor(hours / 24);
        return `${days} (days)`;
    } else {
        let weeks = Math.floor(hours / (24 * 7));
        return `${weeks} (weeks)`;
    }
}

export function ValidateEmail(input) {
    if (input === undefined) {
        return false;
    }

    var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) {
        return true;
    } else {
        return false;
    }
}
export function FormatDateTime(d) {
    if (!d) {
        return "";
    }

    return dayjs(d).format('YYYY-MM-DD hh:mm:ss A');
}

export function FormatDate(d) {
    if (!d) {
        return "";
    }

    return dayjs(d).format('YYYY-MM-DD');
}
export function FormatDateMMDDYYYY(d) {
    if (!d) {
        return "";
    }

    return dayjs(d).format('MM/DD/YYYY');
}

export function FormatDateUTCMMDDYYYY(d) {
    if (!d) {
        return "";
    }

    return dayjs.utc(d).format('MM/DD/YYYY');
}

export function FormatDateUTC(d) {
    if (!d) {
        return "";
    }

    return dayjs.utc(d).format('YYYY-MM-DD');
}

export function DateFromString(d) {
    if (!d) {
        return null;
    }

    if (d.length === 0) {
        return null;
    }

    if (d.length > 4 && (d.slice(0, 4) === '0000' || d.slice(0, 4) === '0001')) {
        return null;
    }

    return dayjs.utc(d).toDate();
}

export function DisplayFormWarning(errors, touched) {
    const errorArray = [];

    for (let k in errors) {
        if (touched.hasOwnProperty(k)) {
            errorArray.push(errors[k]);
        }
    }

    return (
        <Stack direction="column" spacing={2} sx={{ m: 2 }}>
            {errorArray.map((e) => (
                <Alert severity="warning">{e}</Alert>
            ))}
        </Stack>
    );
}
