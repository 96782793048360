import React, { useState, useEffect } from 'react';
import { Grid, Paper, Stack, Divider } from '@mui/material';
import { FormikErrors } from '../ErrorAlert';
import { getFacilities } from "../../services/facilities";
import { getProvidersByFacility } from "../../services/providers";
import { getPatient, searchPatientsByFacility } from "../../services/patients";
import FacilityProviderSelect from '../FacilityProviderSelect';
import PatientSearch from '../PatientSearch';
import OrderPatientDisplay from '../OrderPatientDisplay';
import { useFormikContext } from 'formik';
import { Facility, Patient, Provider } from '../../constants/types';
import { useSearchParams } from 'react-router-dom';

type OrderValues = {
    Facility: Facility | null,
    Provider: Provider | null,
    Patient: Patient | null,
}

function FacilityProviderPatient() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [facilities, setFacilities] = useState([]);
    const [providers, setProviders] = useState([]);
    const [patients, setPatients] = useState([]);
    const { values, errors, touched, setFieldValue, setValues } = useFormikContext<OrderValues>();

    useEffect(() => {
        getFacilities().then((p) => {
            setFacilities(p);
        });
    }, []);

    useEffect(() => {
        let patientID = searchParams.get("patient");
        if (patientID) {
            getPatient(patientID)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load patient");
                    }
                    return p.json();
                })
                .then((p) => {
                    console.log('set patient', p);
                    setValues({ ...values, Patient: p, Facility: p.Facility });
                })
        }
    }, [searchParams]);


    useEffect(() => {
        let newValues = {};
        if (values.Facility?.ID) {
            getProvidersByFacility(values.Facility?.ID).then((p) => {
                setProviders(p);
            });
            searchPatientsByFacility(values.Facility?.ID)
                .then((p) => {
                    return p.json();
                })
                .then((p) => {
                    setPatients(p);
                })
        } else {
            setProviders([]);
            setPatients([]);
        }
        if (values?.Patient && values?.Patient?.FacilityID !== values?.Facility?.ID) {
            newValues = { ...newValues, Patient: null };
        }
        newValues = { ...newValues, Provider: null };
        setValues({ ...values, ...newValues });

    }, [values.Facility?.ID]);

    return (
        <Grid container>
            <Grid item xs={12} md={5} lg={4}>
                <Paper
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        // maxWidth: "95%",
                        m: 2,
                    }}
                >
                    <Stack direction="column" spacing={4}>
                        <FacilityProviderSelect
                            /* @ts-ignore */
                            providers={providers}
                            facilities={facilities}
                        // onFacilityChange={() => setOrder({ ...order, Patient: null })}
                        />
                        <Divider />
                        <PatientSearch
                            patients={patients}
                            patient={values?.Patient}
                            setPatient={(p: any) => setFieldValue('Patient', p)}
                        />
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} md={7} lg={8}>
                {values.Patient?.ID && <OrderPatientDisplay patient={values.Patient?.ID} />}
            </Grid>
        </Grid>
    )
}

export default FacilityProviderPatient;
