import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Alert, InputLabel, Paper, Typography } from "@mui/material";
import Title from "./Title";
import Divider from "@mui/material/Divider";
import { getPatient } from "../services/patients";
import { FormatDateUTC, financialClassIndex } from "../services/util";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Stack } from "@mui/system";
import PatientInsurancesTable from "./InsurancesTable";
import MedicationsTable from "./MedicationsTable";
import AllergysTable from "./AllergyTable";

function PatientInsurancesDetail({ patientDetails }) {
    const [insRow, setInsRow] = useState(patientDetails?.PatientInsurances);

    if (!patientDetails) {
        return <></>
    }

    if (patientDetails.PatientInsurances.length === 0) {
        return <></>;
    }

    return (
        <Box>
            <PatientInsurancesTable
                insurances={insRow}
                setInsurances={setInsRow}
            />
        </Box>
    );
}


export default function OrderPatientDisplay({ patient }) {
    console.log("order patient rendering", patient)
    const [error, setError] = useState("");
    const [patientDetails, setPatientDetails] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("getting patient: ", patient)
        getPatient(patient)
            .then((p) => {
                if (!p.ok) {
                    throw new Error(
                        "Failed to load patient. Please try again."
                    );
                }
                return p.json();
            })
            .then((p) => {
                setError("");
                setPatientDetails(p);
                console.log("setting patient details", p)
            })
            .catch((e) => setError(e.message));
    }, [patient]);

    if (error !== "") {
        console.log(error);
        return (
            <Box width="100%">
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    if (patientDetails === undefined) {
        console.log("Order patient display, patient undefined")
        return <></>;
    }
    return (
        <Box width="100%">
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={12}>
                    <Paper
                        elevation={5}
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            m: 2,
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between">
                            <Title>Selected Patient</Title>
                            <Button
                                onClick={() =>
                                    navigate(
                                        "/patients/" +
                                        patientDetails.ID +
                                        "/edit"
                                    )
                                }
                                startIcon={<ModeEditIcon />}
                            >
                                Edit
                            </Button>
                        </Stack>
                        <Typography variant="h6" display="block" align="left">
                            {patientDetails["LastName"]},{" "}
                            {patientDetails["FirstName"]}
                        </Typography>
                        <Typography variant="h6" display="block" align="left">
                            {FormatDateUTC(patientDetails.DOB)}
                        </Typography>
                        <Typography
                            variant="body1"
                            display="block"
                            align="left"
                        >
                            {patientDetails.Sex}
                        </Typography>
                        <Divider />
                        <Typography variant="body1" display="block">
                            {patientDetails.Address.Address1},{" "}
                            {patientDetails.Address.Address2}
                        </Typography>
                        <Typography variant="body1" display="block">
                            {patientDetails.Address.City},{" "}
                            {patientDetails.Address.State}{" "}
                            {patientDetails.Address.ZipCode}
                        </Typography>
                        <Divider />
                        <Typography variant="body1" display="block">
                            Financial Class:{" "}
                            {financialClassIndex[patientDetails.FinancialClass]}
                        </Typography>
                        {patientDetails.FinancialClass > 1 ? (
                            <>
                                <InputLabel sx={{ mt: 2 }}>
                                    Insurance Details
                                </InputLabel>
                                <PatientInsurancesDetail patientDetails={patientDetails} />
                            </>
                        ) : (
                            <></>
                        )}
                        {patientDetails?.Medications.length > 0 && (
                            <>
                                <InputLabel sx={{ mt: 2 }}>
                                    Reported Medications
                                </InputLabel>
                                <MedicationsTable
                                    medications={patientDetails?.Medications}
                                    overflow={true}
                                />
                            </>
                        )}
                        {patientDetails?.Allergies.length > 0 && (
                            <>
                                <InputLabel sx={{ mt: 2 }}>
                                    Reported Allergies
                                </InputLabel>
                                <AllergysTable
                                    allergys={patientDetails?.Allergies}
                                />
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
