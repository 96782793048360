import React, { useContext } from "react";
import Box from "@mui/material/Box";
import ShipmentsTab from "../components/ShipmentsTab";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import { SessionContext } from "../hooks/getContext";

export default function Shipments() {
    const session = useContext(SessionContext);
    const [value, setValue] = useTabState(0);

    console.log(session)

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    if (session.user.Type === "Lab") {

        return (
            <Framework name="Shipments">
                <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                    <Typography variant="h5">Shipments</Typography>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Incoming" {...a11yProps(0)} />
                            <Tab label="Received" {...a11yProps(0)} />
                            <Tab label="Outgoing" {...a11yProps(0)} />
                            <Tab label="All" {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                </Box>
                <TabPanel value={value} index={0}>
                    <ShipmentsTab tab={"incoming"} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ShipmentsTab tab={"received"} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ShipmentsTab tab={"outgoing"} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <ShipmentsTab tab={"all"} />
                </TabPanel>
            </Framework>
        );
    } else {
        return (
            <Framework name="Shipments">
                <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                    <Typography variant="h5">Shipments</Typography>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Outgoing" {...a11yProps(0)} />
                            <Tab label="Received" {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                </Box>
                <TabPanel value={value} index={0}>
                    <ShipmentsTab tab={"incoming"} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ShipmentsTab tab={"received"} />
                </TabPanel>
            </Framework>
        );

    }
}
