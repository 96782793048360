import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/system/Box";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import { Stack } from "@mui/system";
import {
    InputLabel,
    Alert,
    Autocomplete,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getDiagnosisRules, getDiagnosisCodes } from "../services/orders";
import { DataGridPro, GridRowParams, GridActionsCellItem, GridRowId } from "@mui/x-data-grid-pro";

import { Button, TextField } from "@mui/material";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import { CustomCheckboxField, CustomTextField } from "./CustomTextField";
import { DiagnosisCode, DiagnosisValidityRule } from "../constants/types";
import { apiFetch } from "../services/fetch";
import Delete from "@mui/icons-material/Delete";

const defaultRule = {
    ConvertedNCDRule: "",
    CPTCode: "",
    Supported: true,
    DiagnosisCode: null
}

export default function DiagnosisRulesPanel() {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 50,
    });
    const [diagnosiss, setDiagnosiss] = useState([]);
    const [error, setError] = useState(null);
    const [diagnosisSearch, setDiagnosisSearch] = useState("");
    const [allDiagnosisCodes, setAllDiagnosisCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [saving, setSaving] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [create, setCreate] = useState(false);
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [filter, setFilter] = useState("");
    const [initialValues] = useState<DiagnosisValidityRule>(defaultRule);

    useEffect(() => {
        if (diagnosisSearch === "" || diagnosisSearch.length < 2) {
            return;
        }
        setLoading(true);
        getDiagnosisCodes(diagnosisSearch)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load diagnosis codes");
                }
                return p.json();
            })
            .then((p) => {
                setAllDiagnosisCodes(p.DiagnosisCodes);
                setLoading(false);
            })
            .catch((e) => setError(e.message));
    }, [diagnosisSearch]);

    useEffect(() => {
        console.log(paginationModel);
        setLoading(true);
        getDiagnosisRules(
            filter,
            String(paginationModel.pageSize),
            String(paginationModel.page)
        )
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load diagnosiss");
                }
                return p.json();
            })
            .then((p) => {
                setLoading(false);
                setDiagnosiss(p.DiagnosisValidity);
                setRowCount(p.Count);
            })
            .catch((e) => {
                setError(e.message);
                setLoading(false);
            });
    }, [paginationModel, filter, refresh]);

    const validationSchema = Yup.object().shape({
        ConvertedNCDRule: Yup.string().required("NCD/LCD is required"),
        CPTCode: Yup.string().required("CPT Code is required"),
        DiagnosisCode: Yup.object({
            Code: Yup.string().required(),
            OrderNumber: Yup.number().required(),
            Description: Yup.string().required(),
        }
        ).required("Diagnosis code is required"),
    });


    const handleSubmit = async (values: any) => {
        setSaving(true)
        console.log(values);
        try {
            await apiFetch('/orders/diagnosis/rules', 'POST', values)
            setRefresh(!refresh)
            setCreate(false)
        } catch (e) {
            setError(error)
        } finally {
            setSaving(false)
        }
    };

    const handleDelete = async (id: GridRowId) => {
        try {
            await apiFetch(`/orders/diagnosis/rules/${id}`, 'DELETE')
        } catch (e) {
            setError(error)
        } finally {
            setRefresh(!refresh)
        }
    }

    useEffect(() => {
        // needed to keep position in pages
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState
        );
    }, [rowCount, setRowCountState]);

    const handleFilterChange = (value: any) => {
        setFilter(value);
    };
    const getCheckbox = (params: any) => {
        if (params.row.Supported) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const columns = [
        { field: "ConvertedNCDRule", headerName: "NCD/LCD", width: 100 },
        { field: "CPTCode", headerName: "CPT Code", width: 110 },
        { field: "DiagnosisCode", headerName: "Diagnosis Code", width: 150 },
        {
            field: "Supported",
            headerName: "Supported",
            width: 110,
            renderCell: getCheckbox,
        },
        {
            field: "actions",
            type: "actions",
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem icon={<Delete />} onClick={() => handleDelete(params.id)} label="Delete" />,
            ]
        }
    ];

    const QuickFilterToolbar = (props: any) => {
        const { onFilterChange } = props;

        const handleChange = useCallback(
            (event: any) => {
                const value = event.target.value;
                setFilter(value);
                onFilterChange(value);
            },
            [onFilterChange]
        );

        // useEffect(() => {
        // return apiRef.current.subscribeToStateChange(
        // ({ api, state, type }) => {
        // if (type === "columnFilterChange") {
        // setFilterValue("");
        // }
        // },
        // { columnFilterChange: true }
        // );
        // }, [apiRef]);

        return (
            <TextField
                // value={filter}
                onBlur={handleChange}
                placeholder="Quick Filter"
                variant="standard"
            />
        );
    };

    if (create) {
        return (
            <Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        errors,
                        touched,
                    }) => (
                        <Form>
                            <Stack direction="column" spacing={2}>
                                <InputLabel>General</InputLabel>
                                <Stack
                                    direction={{ sm: "column", md: "row" }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}
                                >
                                    <CustomTextField
                                        values={values}
                                        onBlur={handleBlur}
                                        required={true}
                                        name="ConvertedNCDRule"
                                        label="NCD/LCD"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                    <CustomTextField
                                        values={values}
                                        onBlur={handleBlur}
                                        required={true}
                                        name="CPTCode"
                                        label="CPT Code"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                    <Autocomplete
                                        options={allDiagnosisCodes}
                                        onChange={(_, v) => {
                                            setFieldValue("DiagnosisCode", v)
                                        }}
                                        onInputChange={(_, v) =>
                                            setDiagnosisSearch(v)
                                        }
                                        disableListWrap
                                        filterOptions={(x) => x}
                                        value={values?.DiagnosisCode}
                                        noOptionsText={
                                            diagnosisSearch === ""
                                                ? "Enter at least two characters to search"
                                                : "No codes found"
                                        }
                                        loading={loading}
                                        getOptionLabel={(option: DiagnosisCode) => {
                                            if (!option) {
                                                return ""
                                            }
                                            return option.Code +
                                                ": " +
                                                option.Description
                                        }
                                        }
                                        isOptionEqualToValue={(option, value) =>
                                            option.Code === value.Code
                                        }
                                        sx={{
                                            minWidth: "200px",
                                            width: "400px",
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="ICD-10 Code"
                                                placeholder="Search for ICD-10 code"
                                                name="DiagnosisCode"
                                                required
                                                size="small"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {loading ? (
                                                                <CircularProgress
                                                                    color="inherit"
                                                                    size={20}
                                                                />
                                                            ) : null}
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .endAdornment
                                                            }
                                                        </>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                    <CustomCheckboxField
                                        values={values}
                                        name="Supported"
                                        lable="Supported"
                                        handleChange={handleChange}
                                    />
                                </Stack>
                                {error !== null ? (
                                    <Alert severity="error">{error}</Alert>
                                ) : null}
                                <Button type="submit" disabled={saving}>
                                    Create
                                </Button>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Box>
        );
    } else {
        return (
            <TableFrame>
                <DataTableContainer>
                    <DataGridPro
                        getRowId={(row: any) => row.ID}
                        pagination
                        density="compact"
                        loading={loading}
                        filterMode="server"
                        components={{ Toolbar: QuickFilterToolbar }}
                        componentsProps={{
                            toolbar: { onFilterChange: handleFilterChange },
                        }}
                        paginationMode="server"
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        rowCount={rowCountState}
                        rows={diagnosiss}
                        columns={columns}
                    />
                </DataTableContainer>
                <ButtonTray>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => setCreate(true)}
                    >
                        Create
                    </Button>
                </ButtonTray>
            </TableFrame>
        );
    }
}
